import {
  IonContent,
  IonHeader,
  IonPage,
  useIonViewDidEnter,
} from '@ionic/react'
import { forwardRef } from 'react'
import Loading from '../components/Loading'
import { useTabs } from '../components/Main'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Capacitor } from '@capacitor/core'
import { SecondaryLayoutProps } from '~types/components'

const SecondaryLayout = forwardRef<HTMLIonContentElement, React.PropsWithChildren<SecondaryLayoutProps>>(({ children, toolbar, footer, loading, showTabs = false, scrollY = true }, ref) => {
  const { setShowTabs } = useTabs()

  useIonViewDidEnter(() => {
    setShowTabs(showTabs)

    try {
      if (Capacitor.isPluginAvailable('StatusBar')){
        StatusBar.setStyle({ style: Style.Dark })
        StatusBar.setBackgroundColor({ color: '#FF8243' })
      }
    } catch (error) { 
      console.error(error)
    }
  })

  const contentStyle = {
    '--padding-bottom': '50px',
  }

  return (
    <IonPage>
      <IonHeader style={{ paddingTop: 0 }}>
        { toolbar }
      </IonHeader>
      <Loading isLoading={loading} />
      <IonContent class="ion-padding" ref={ref} scrollY={scrollY} style={contentStyle}>
        { children }
      </IonContent>
      { footer }
    </IonPage>
  )
})

SecondaryLayout.displayName = 'SecondaryLayout'

export default SecondaryLayout