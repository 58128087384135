import { IonToast } from '@ionic/react'
import { informationCircle } from 'ionicons/icons'
import { ToastTutorialProps } from '~types/components'

const ToastTutorial: React.FC<ToastTutorialProps> = ({ showBanner, setShowBanner }: ToastTutorialProps) => {
  const buttons = [
    {
      text: 'Annulla',
      role: 'cancel',  
    },
  ]

  return (
    <IonToast
      buttons={buttons}
      color="info"
      cssClass="banner-tutorial"
      icon={informationCircle}
      isOpen={showBanner}
      message="Consulta i nostri <a href='/profile/settings/tutorial'>tutorial</a> per una migliore esperienza"
      onDidDismiss={() => setShowBanner(false)}
      position="bottom"
    />
  )
}

export default ToastTutorial