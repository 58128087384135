import {
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonGrid,
  IonImg,
  IonAvatar,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
} from '@ionic/react'
import axios from 'axios'

import React from 'react'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useToast } from '../providers/ToastProvider'
import SecondaryLayout from '../layout/SecondaryLayout'
import SecondaryToolbar from '../components/SecondaryToolbar'
import BookpointShelfsModal from '../components/BookpointShelfsModal'
import { useDevice } from '../providers/DeviceProvider'
import BookPointDetailsLoading from '../components/BookPointDetailsLoading'

import type { Service } from '~types/bookpoint'

const BookPointDetails: React.FC<RouteComponentProps> = ({ match }) => {
  const [loading, setLoading] = useState(false)
  const [bookPoint, setBookPoint] = useState<any>({})
  const [showShelfsModal, setShowShelfsModal] = useState(false)
  const { safeArea } = useDevice()
  const toast = useToast()
  // const gaEventTracker = useAnalyticsEventTracker('Bookpoint');
  // const cookies = new Cookies();
  const params: any = match.params

  const loadBookPointDetails = async () => {
    setLoading(true)
    
    try {
      const { data } = await axios.get(`/bookpoints/${params.id}`)
      setBookPoint(data)
    } catch (error) {
      console.error(error)
      toast.error('Errore nel recupero del dettaglio del BookPoint')
    }

    setLoading(false)
  }

  useEffect(() => {
    loadBookPointDetails()
  }, [])

  // useIonViewDidEnter(() => {
  //   if (cookies.get("user-has-accepted-cookies")) {
  //     gaEventTracker('Open Bookpoint Details')
  //   }
  // })

  const descriptionStyle = {
    backgroundColor: 'var(--ion-color-primary)',
    color: 'black',
    borderRadius: '0px 10px 10px',
    padding: 10,
    TextAlign: 'left',
    fontSize: 13,
  }

  const iconStyle = {
    marginRight: 6,
    fontSize: 16,
  }

  const titleStyle = {
    fontWeight: 400,
    padding: '3px 60px',
  }

  const imgStyle = {
    display: 'block',
    objectFit: 'cover',
    height: 300,
  }

  const bookPointAvatarStyle = { 
    position: 'absolute', 
    margin: 15,
    background: bookPoint.type !== 'other' ? 'linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 100%)' : null,
    width: 60,
    height: 60,
    display: bookPoint.type === 'other' ? 'flex' : null,
    alignItems: bookPoint.type === 'other' ? 'center' : null,
    justifyContent: bookPoint.type === 'center' ? 1 : null,
    backdropFilter: bookPoint.type === 'other' ? 'blur(5px)' : null,
    zIndex: 10,
  }

  const bookPointIconStyle = {
    color: bookPoint.color, 
    fontSize: 50,
    textAlign: 'center',
    flexGrow: 1,
  }

  return (
    <SecondaryLayout
      loading={loading}
      toolbar={
        <SecondaryToolbar className="secondary-toolbar">
          { !loading && (
            <IonButtons slot="start">
              <IonBackButton
                color="dark"
                defaultHref="/bookPoints"
                text=""
              />
            </IonButtons>
          ) }
          <IonTitle style={titleStyle}>
            { bookPoint.full_name }
          </IonTitle>
        </SecondaryToolbar>
      }
    >
      { loading ? 
        <BookPointDetailsLoading /> : 
        <>
          <IonCard class="ion-no-margin">
            <IonAvatar style={bookPointAvatarStyle}>
              { bookPoint.type === 'other' ? 
                <IonIcon icon={bookPoint.icon} style={bookPointIconStyle}/>
                :
                <IonImg src="/assets/icon/favicon.png" style={{ width: 60, height: 60 }} /> }
            </IonAvatar>
            { bookPoint.carousel_images ?
              <Swiper pagination style={{ height: 300 }}>
                {
                  bookPoint.carousel_images?.map((image: any, i: number) => {
                    return (
                      <SwiperSlide key={i}>
                        <IonImg src={image} style={imgStyle} />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
              :
              <IonImg src="/images/bookpoint-no-picture.jpg" /> }
            <IonCardHeader
              color="light"
              style={{ background: '#eaeaea', padding: 10 }}
            >
              { bookPoint.full_name }
            </IonCardHeader>
            <IonCardContent style={{ padding: 0 }}>
              <IonGrid style={{ padding: '10px 0px' }}>
                <IonRow>
                  <IonCol size="9">
                    <IonRow class="ion-align-items-center" >
                      <IonCol class="ion-no-padding">
                        <IonIcon
                          color="secondary"
                          icon="time-outline"
                          style={{ ...iconStyle, verticalAlign: 'middle', paddingBottom: 3 }}
                        />
                        <IonText>
                          {
                            bookPoint.current_timetable ?
                              `${bookPoint.current_timetable?.label} ${bookPoint.current_timetable?.start} - ${bookPoint.current_timetable?.end}`
                              :
                              'Oggi Chiuso'
                          }
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow class="ion-align-items-center">
                      <IonCol class="ion-no-padding">
                        <IonIcon
                          color="secondary"
                          icon="location-outline"
                          style={{ ...iconStyle, verticalAlign: 'middle', paddingBottom: 3 }}
                        />
                        <IonText>
                          { bookPoint.address } { bookPoint.city } { bookPoint.province }
                        </IonText>
                      </IonCol>
                    </IonRow>
                    { bookPoint.web_site ?
                      <IonRow class="ion-align-items-center">
                        <IonCol class="ion-no-padding">
                          <IonIcon
                            color="secondary"
                            icon="globe-outline"
                            style={{ ...iconStyle, verticalAlign: 'middle', paddingBottom: 3 }}
                          />
                          <a href={bookPoint.web_site} rel="noreferrer" target="_blank">
                            { bookPoint.web_site?.replace('https://', '') }
                          </a>
                        </IonCol>
                      </IonRow> : null }
                  </IonCol>
                  { bookPoint.type !== 'other' && (
                    <IonCol class="ion-text-center">
                      <IonButton
                        color="light"
                        onClick={() => setShowShelfsModal(true)}
                      >
                        <IonIcon
                          color="secondary"
                          icon="book-outline"
                          style={{ fontSize: 32 }}
                        />
                      </IonButton>
                    </IonCol>
                  ) }
                </IonRow>
              </IonGrid>

              <div style={descriptionStyle}>
                <IonText>
                  { bookPoint.bio }
                </IonText>
              </div>

              <IonGrid class="ion-padding-top">
                <IonRow>
                  {
                    bookPoint.services?.map((s: Service, i: number) =>
                      (<React.Fragment key={i}>
                        <IonCol class="ion-no-padding" size="1" style={{ maxWidth: 25 }} >
                          <IonIcon color="secondary" icon={s.icon} style={iconStyle} />
                        </IonCol>
                        <IonCol class="ion-no-padding" size="5">
                          <IonLabel>
                            { s.label }
                          </IonLabel>
                        </IonCol>
                      </React.Fragment>),
                    )
                  }
                </IonRow>
              </IonGrid>

            </IonCardContent>
          </IonCard>
          {
            bookPoint.stats && bookPoint.type !== 'other' ? <IonGrid style={{ paddingBottom: (safeArea?.insets.bottom ?? 0) + 10 }}>
              <IonToolbar class="light-toolbar">
                <IonTitle style={titleStyle}>
                  Statistiche
                </IonTitle>
              </IonToolbar>
              <IonList>
                <IonItem lines="full">
                  <IonLabel>
                    <h5>
                      Libri messi a disposizione
                    </h5>
                  </IonLabel>
                  <IonBadge color="primary" slot="end">
                    { bookPoint.stats.shelfs }
                  </IonBadge>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>
                    <h5>
                      Scambi avvenuti presso questo Bookpoint
                    </h5>
                  </IonLabel>
                  <IonBadge color="primary" slot="end">
                    { bookPoint.stats.borrows }
                  </IonBadge>
                </IonItem>
              </IonList>
            </IonGrid> : null
          }
        </> }

      <BookpointShelfsModal
        bookPoint={bookPoint}
        isOpen={showShelfsModal}
        onDidDismiss={() => setShowShelfsModal(false)}
      />
    </SecondaryLayout>
  )
}

export default BookPointDetails