
import { StatusBar, Style } from '@capacitor/status-bar'
import {
  IonContent,
  IonHeader,
  IonPage,
  useIonViewDidEnter,
} from '@ionic/react'
import { PropsWithChildren, ReactNode } from 'react'
import Loading from '../components/Loading'
import { useTabs } from '../components/Main'
import { Capacitor } from '@capacitor/core'

interface DefaultLayoutProps {
  toolbar: ReactNode,
  loading?: boolean,
  contentStyle?: any,
  headerStyle?: any,
}

const DefaultLayout: React.FC<PropsWithChildren<DefaultLayoutProps>> = ({ children, toolbar, loading, contentStyle, headerStyle }: PropsWithChildren<DefaultLayoutProps>) => {
  const { setShowTabs } = useTabs()

  useIonViewDidEnter(() => {
    setShowTabs(true)

    try {
      if (Capacitor.isPluginAvailable('StatusBar')){
        StatusBar.setStyle({ style: Style.Light })
        StatusBar.setBackgroundColor({ color: '#FFFFFF' })
      }   
    } catch (error) { 
      console.error(error)
    }
  })

  const headerInnerStyle = {
    paddingTop: 0,
    //paddingTop: isAndroid ? statusBarHeight : '',
    ...headerStyle,  
  }

  const contentInnerStyle = {
    '--padding-bottom': 'calc(env(safe-area-inset-bottom) + 50px)',
    ...contentStyle,
  }

  return (
    <IonPage>
      <IonHeader style={headerInnerStyle}>
        { toolbar }
      </IonHeader>
      <Loading isLoading={loading} />
      <IonContent style={contentInnerStyle}>
        { children }
      </IonContent>
    </IonPage>
  )
}

export default DefaultLayout