import {
  IonCard,
  IonText,
  IonRow,
  IonCol,
  IonImg,
  IonGrid,
  IonAvatar,
  IonIcon,
} from '@ionic/react'
import Dot from './Dot'

import type { BookPointCardProps } from '~types/components'

const BookPointCard: React.FC<BookPointCardProps> = ({ bookPoint, onClick }: BookPointCardProps) => {
  const distance = (d: number) => {
    let ret = 'a '
    ret += d >= 1000 ?
      (Number((d / 1000).toFixed(1))) + ' km'
      :
      d + ' m'
    ret += ' da te'
    return ret
  }

  const cardStyle = {
    background: '#eaeaea',
    height: 'fit-content',
  }

  const imgStyle = {
    display: 'block',
    objectFit: 'cover',
    height: 170,
  }

  const bookPointAvatarStyle = { 
    position: 'absolute', 
    margin: 15,
    background: bookPoint.type !== 'other' ? 'linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 100%)' : null,
    width: 60,
    height: 60,
    display: bookPoint.type === 'other' ? 'flex' : null,
    alignItems: bookPoint.type === 'other' ? 'center' : null,
    justifyContent: bookPoint.type === 'center' ? 1 : null,
    backdropFilter: bookPoint.type === 'other' ? 'blur(5px)' : null,
  }

  const bookPointIconStyle = {
    color: bookPoint.color, 
    fontSize: 50,
    textAlign: 'center',
    flexGrow: 1,
  }

  return (
    <IonCard
      onClick={onClick}
      style={cardStyle}
    >
      <IonAvatar style={bookPointAvatarStyle}>
        { bookPoint.type === 'other' ? 
          <IonIcon icon={bookPoint.icon} style={bookPointIconStyle}/>
          :
          <IonImg src="/assets/icon/favicon.png" style={{ width: 60, height: 60 }} /> }
      </IonAvatar>
      <IonImg
        src={bookPoint.image ?? '/images/bookpoint-no-picture.jpg'}
        style={imgStyle}
      />

      <IonGrid>
        <IonRow>
          <IonCol>
            <IonText><strong>{ bookPoint.full_name }</strong></IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          { bookPoint.distance ? 
            <IonCol size="9">
              <IonText>
                { distance(bookPoint.distance) }
              </IonText>
            </IonCol> : null }
          <IonCol offset={bookPoint.distance ? '0' : '9'} size="3">
            <Dot open={bookPoint.open} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  )
}

export default BookPointCard