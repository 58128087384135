import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonFooter,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
} from '@ionic/react'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useHistory } from 'react-router'
import { format, parse, parseISO } from 'date-fns'
import { useToast } from '../providers/ToastProvider'
import { Camera, CameraResultType } from '@capacitor/camera'
import { cameraOutline, imagesOutline } from 'ionicons/icons'
import { useAuth } from '../providers/AuthProvider'
import { useDevice } from '../providers/DeviceProvider'
import { DateTime } from 'luxon'
import { B2BUser } from '~types/b2b-user'

const schema = Yup.object().shape({
  email: Yup.string().email().required('Campo obbligatorio'),
  first_name: Yup.string().required('Campo obbligatorio'),
  last_name: Yup.string().required('Campo obbligatorio'),

  gender: Yup.string()
    .oneOf(['male', 'female', 'other'], 'Seleziona una delle opzioni')
    .required('Campo obbligatorio'),

  birthday: Yup.string()
    .matches(/^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/, 'Utilizza il formato gg/mm/aaaa')
    .test('format', 'Data non valida', (date: any) => {
      return date ? (DateTime.fromFormat(date, 'dd/MM/yyyy').year ?? 0) <= 9999 : true
    })
    .test(
      'DOB',
      'Questo servizio è dedicato ai maggiorenni.',
      (date: any) => {
        return date ? DateTime.fromFormat(date, 'dd/MM/yyyy')
          .diffNow()
          .as('years') <= -18 : true
      },
    )
    .nullable(),
})

interface FormProfileProps {
  user: any,
  isModal?: boolean,
  onModalSubmit?: any,
}

const FormProfile: React.FC<FormProfileProps> = ({ user, isModal, onModalSubmit }: FormProfileProps) => {
  let defaultValues = {
    email: '',
    first_name: '',
    last_name: '',
    gender: 'none',
    birthday: '',
    bio: '',
    avatar: '' as string | undefined,
    address: '',
    city: '',
    zip: '',
  }

  const toast = useToast()
  const history = useHistory()
  const [showDateModal, setShowDateModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [photoLoaded, setPhotoLoaded] = useState(false)
  const { setB2BUser } = useAuth()
  const { isWeb, isAndroid, safeArea, showKeyboard } = useDevice()
  const [showName, setShowName] = useState(true)
  const [birthdayCheck, setBirthdayCheck] = useState<boolean | undefined>(false)
  const { register, getValues, setValue, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    reset(user)
    setValue('birthday', formatDate(user?.birthday, 'dd/MM/yyyy'))
    if (!isModal && user.displayName) {
      const names = user.displayName.split(' ')
      if (names) {
        setValue('first_name', names[0] || '')
        names?.shift()
        setValue('last_name', names?.join(' '))
      }
    } else if (!isModal && user.email.includes('@privaterelay.appleid.com')) {
      setShowName(false)
      setValue('first_name', 'Utente')
      setValue('last_name', 'Anonimo')
    }
  }, [])

  const onSubmit = async (data: any) => {
    setLoading(true)

    try {
      data.birthday = parseDate(data.birthday, 'dd/MM/yyyy')

      const user: B2BUser = await axios.put('/user', data)

      toast.success('Profilo utente aggiornato')

      setB2BUser(user)

      if (history.location.pathname === '/firstLogin')
        history.push('/home', { showBannerTutorial: true })

      if (isModal)
        onModalSubmit()
    } catch (error) {
      console.error(error)
      toast.error('Errore nel salvataggio dell\'utente')
    }

    setLoading(false)
  }

  const formatDate = (value: string | string[], dateFormat: string = 'dd-MM-yyyy'): string => {
    try {
      return !Array.isArray(value) ? format(parseISO(value), dateFormat) : value[0]
    } catch (error) {
      return !Array.isArray(value) ? value : value[0]
    }
  }

  const parseDate = (value: string, dateFormat: string = 'dd/MM/yyyy') => {
    try {
      return formatDate(parse(value, dateFormat, new Date()).toISOString(), 'yyyy-MM-dd')
    } catch (error) {
      return value
    }
  }

  const loadImage = async () => {
    try {
      const image = await Camera.getPhoto({
        allowEditing: true,
        resultType: CameraResultType.Base64,
        promptLabelHeader: 'Foto Profilo',
        promptLabelCancel: 'Cancella',
        promptLabelPhoto: 'Galleria',
        promptLabelPicture: 'Scatta Foto',
      })
      setValue('avatar', 'data:image/png;base64,' + image.base64String)
      toast.success('Foto Profilo caricata con successo')
      setPhotoLoaded(true)
    } catch (error: any) {
      if (error.message !== 'User cancelled photos app') {
        console.error(error)
        toast.error('Errore nel caricamento dell\'immagine: ' + error.message)
      }
    }
  }

  const inputStyle = {
    background: '#eaeaea',
    margin: '5px 0px 0px',
  }
  const errorStyle = {
    fontSize: 12,
    padding: '0 10px 10px 10px',
  }

  const labelStyle = {
    paddingLeft: 13,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  }

  const cardStyle = {
    background: '#eaeaea',
    boxShadow: 'none',
    width: '100%',
    margin: '5px 0px 0px',
  }

  const checkboxStyle = {
    '--background': '#eaeaea',
  }

  const successCardStyle = {
    color: 'success',
    margin: '0px 30px',
  }

  const userModalFooterStyle = {
    position: 'fixed',
    bottom: history.location.pathname === '/firstLogin' ? 50 : (isAndroid ? 30 : 4),
    left: 0,
    backgroundColor: 'white',
  }
  const saveButtonStyle = {
    marginBottom: isWeb ? 8 : (showKeyboard ? 4 : ((safeArea?.insets.bottom ?? 0) + 10)),
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 100 }}>

        <IonLabel style={labelStyle}>Mail</IonLabel>
        <IonInput style={inputStyle} type="text" {...register('email')} />
        { errors.email ? <div><IonText color="danger" style={errorStyle}>{ errors.email?.message }</IonText></div> : null }
        <br />

        { showName ? 
          <> 
            <IonLabel style={labelStyle}>Nome</IonLabel>
            <IonInput style={inputStyle} type="text" {...register('first_name')} />
            { errors.first_name ? <div><IonText color="danger" style={errorStyle}>{ errors.first_name?.message }</IonText></div> : null }
            <br />

            <IonLabel style={labelStyle}>Cognome</IonLabel>
            <IonInput style={inputStyle} type="text" {...register('last_name')} />
            { errors.last_name ? 
              <div><IonText color="danger" style={errorStyle}>{ errors.last_name?.message }</IonText></div> : null }
            <br />
          </> : null }
        

        <IonLabel style={labelStyle}>Genere in cui ti riconosci</IonLabel>
        <IonCard style={cardStyle}>
          <IonCardContent style={{ padding: '10px 4px' }}>
            <IonRadioGroup {...register('gender')} onIonChange={e => setValue('gender', e.detail.value)}>
              <IonItem lines="none" style={checkboxStyle}>
                <IonLabel>Femmina</IonLabel>
                <IonRadio slot="start" value="female" />
              </IonItem>
              <IonItem lines="none" style={checkboxStyle}>
                <IonLabel>Maschio</IonLabel>
                <IonRadio slot="start" value="male" />
              </IonItem>
              <IonItem lines="none" style={checkboxStyle}>
                <IonLabel>Altro</IonLabel>
                <IonRadio slot="start" value="other" />
              </IonItem>
            </IonRadioGroup>
          </IonCardContent>

        </IonCard>
        { errors.gender ? <div><IonText color="danger" style={errorStyle}>{ errors.gender?.message }</IonText></div> : null }
        <br />

        { /* <IonLabel style={labelStyle}>Data di nascita</IonLabel>
        <IonInput style={inputStyle} type="text" {...register("birthday")} />
        {errors.birthday && <div><IonText style={errorStyle} color="danger">{errors.birthday?.message}</IonText></div>} */ }
        { !isModal && (
          <IonLabel style={{ fontSize: 12 }}>
            Dichiaro di essere maggiorenne
            <IonCheckbox checked={birthdayCheck} onIonChange={e => setBirthdayCheck(e.detail.checked)} style={{ verticalAlign: 'middle', marginLeft: 10 }} />
          </IonLabel>
        ) }
        { isModal ? <>
          <br />
          <IonLabel style={labelStyle}>Indirizzo</IonLabel>
          <IonInput style={inputStyle} type="text" {...register('address')} />
          { errors.address ? <div><IonText color="danger" style={errorStyle}>{ errors.address?.message }</IonText></div> : null }
          <br />
          <IonLabel style={labelStyle}>Città</IonLabel>
          <IonInput style={inputStyle} type="text" {...register('city')} />
          { errors.city ? <div><IonText color="danger" style={errorStyle}>{ errors.city?.message }</IonText></div> : null }
          <br />
          <IonLabel style={labelStyle}>CAP</IonLabel>
          <IonInput style={inputStyle} type="text" {...register('zip')} />
          { errors.zip ? <div><IonText color="danger" style={errorStyle}>{ errors.zip?.message }</IonText></div> : null }
          <br />
          <IonLabel style={labelStyle} >Bio</IonLabel>
          <IonTextarea rows={10} style={inputStyle} {...register('bio')} />
          <IonRow class="ion-align-items-center" style={{ marginTop: 5 }}>
            <IonCol size={photoLoaded ? '7' : '9'}>
              <IonLabel>Immagine profilo</IonLabel>
            </IonCol>
            { photoLoaded ? <IonCol size="2">
              <IonAvatar >
                <IonImg src={getValues('avatar')} style={{ maxHeight: 45, maxWidth: 45 }} />
              </IonAvatar>
            </IonCol> : null }
            <IonCol class="ion-text-end" size="3">
              <IonButton
                color="primary"
                onClick={loadImage}
              >
                <IonIcon
                  icon={cameraOutline}
                  style={{ fontSize: 30 }}
                />
              </IonButton>
            </IonCol>
          </IonRow>

          { photoLoaded ? <IonCard color="success" style={successCardStyle}>
            <IonCardContent style={{ padding: '5px 0px', fontSize: 14, textAlign: 'center' }} >
              <IonIcon
                icon={imagesOutline}
                style={{ verticalAlign: 'middle', fontSize: 20, paddingBottom: 2, paddingRight: 7 }}
              />
              Foto Caricata con successo
            </IonCardContent>
          </IonCard> : null }
        </> : null }
        <IonFooter style={userModalFooterStyle}>
          <IonButton 
            disabled={loading || (isModal ? false : !birthdayCheck)} 
            expand='block' 
            style={saveButtonStyle}
            type='submit'>
            { loading ?
          
              <IonSpinner name="dots" />
              :
              'Salva' }
          </IonButton>
        </IonFooter>
      </form>
      <IonModal
        className="date-modal"
        isOpen={showDateModal}
        onDidDismiss={() => setShowDateModal(false)}
      >
        <IonContent force-overscroll="false">
          <IonDatetime
            cancelText="Cancella"
            doneText="Ok"
            locale="it-IT"
            max={formatDate(new Date().toISOString(), 'yyy-MM-dd')}
            onIonChange={e => {
              setValue('birthday', formatDate(e.detail.value!, 'dd/MM/yyyy'))
            }}
            presentation="date"
            showDefaultButtons
            value={parseDate(getValues('birthday'), 'dd/MM/yyyy')} />
        </IonContent>
      </IonModal>
    </div>
  )
}

export default FormProfile