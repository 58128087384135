import {
  IonCol,
  IonRow,
  IonFab,
  IonFabButton,
  IonIcon,
  IonTitle,
  IonLabel,
  IonInput,
  IonBackButton,
  IonButtons,
  IonModal,
  IonGrid,
  IonText,
  IonButton,
  useIonLoading,
  IonNote,
} from '@ionic/react'
import axios from 'axios'
import { RouteComponentProps, useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import { checkmarkOutline } from 'ionicons/icons'
import { useToast } from '../../providers/ToastProvider'
import { format, parseISO } from 'date-fns'
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker'
import { Cookies } from 'react-cookie'
import SecondaryLayout from '../../layout/SecondaryLayout'
import SecondaryToolbar from '../../components/SecondaryToolbar'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  volumeInfo: Yup.object().shape({
    title: Yup.string().required('Campo obbligatorio'),
    authors: Yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value
        }
        return originalValue ? originalValue.split(/[,]/) : []
      })
      .min(1, 'Deve esserci almeno un autore')
      .of(Yup.string()),
    publishedDate: Yup.string().matches(/^([0-9]{4})$/, 'L\'anno deve essere nel formato aaaa'),
    publisher: Yup.string(),
  }),
})

const Review: React.FC<RouteComponentProps> = ({ location }: RouteComponentProps) => {
  let defaultValues = {
    volumeInfo: {
      title: '',
      authors: '',
      publishedDate: '',
      publisher: '',
    },
  }

  const locationState: any = location.state
  const type = locationState?.type
  const [newBook, setNewBook] = useState<any>({})
  const [showModal, setShowModal] = useState(false)
  const [present, dismiss] = useIonLoading()
  const history = useHistory()
  const toast = useToast()
  const gaEventTracker = useAnalyticsEventTracker('Book')
  const cookies = new Cookies()
  const { 
    register, 
    getValues,
    trigger,
    setValue,
    handleSubmit,
    formState: { 
      errors, 
    }, 
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (locationState?.book) {
      switch (type) {
        case 'isbn':
          if(Array.isArray(locationState.book.volumeInfo.authors))
            locationState.book.volumeInfo.authors = locationState?.book?.volumeInfo?.authors?.join(',')
          locationState.book.volumeInfo.publishedDate = formatDate(locationState.book.volumeInfo.publishedDate)
          setNewBook(locationState.book)
          setValue('volumeInfo', locationState.book.volumeInfo)
          break
        case 'list':
          if(Array.isArray(locationState.book.document.volumeInfo.authors))
            locationState.book.document.volumeInfo.authors = locationState?.book?.document?.volumeInfo?.authors?.join(',')
          locationState.book.document.volumeInfo.publishedDate = formatDate(locationState.book.document.volumeInfo.publishedDate)
          setNewBook(locationState.book.document)
          setValue('volumeInfo', locationState.book.document.volumeInfo)
          break
        case 'form':
          setNewBook(locationState.book)
          break
        default:
          setNewBook(locationState.book)
      }
      delete locationState.book
    }
  }, [locationState])

  const openModal = async () => {
    const validate = await trigger()
    if(validate)
      setShowModal(true)
  }

  const checkType = (data: any) => {
    return Object.keys(defaultValues.volumeInfo).every(k => {
      return data.volumeInfo[k] === newBook.volumeInfo[k]
    }) ? type : 'manual'
  }

  const onSubmit = async (data: any) => {
    const computedType = checkType(data)
    const book = {
      ...newBook,
      volumeInfo: {
        ...data.volumeInfo, 
        authors: data.volumeInfo.authors,
      },
    }
   
    setShowModal(false)
    present('Aggiunta Libro...')
    try {
      await axios.post('/shelfs', {
        book,
        type: computedType,
      })
      if (cookies.get('user-has-accepted-cookies')) {
        gaEventTracker('Create new Book')
      }
      toast.success('Libro aggiunto correttamente')
      // sono riuscito ad aggiungere il libro alla mia libreria
      history.push('/myShelf', { refresh_shelf: true })
    } catch (error: any) {
      console.error(error)
      toast.error(error.response.data.message)
    }
    dismiss()
  }

  const formatDate = (value: string, dateFormat: string = 'yyyy') => {
    try {
      return format(parseISO(value), dateFormat)
    } catch (error) {
      return value
    }
  }

  const inputStyle = {
    background: '#eaeaea',
    margin: '5px 0px 0px',
  }

  const labelStyle = {
    fontWeight: 'bold',
    margin: 'auto',
    paddingLeft: 13,
    textTransform: 'uppercase',
  }

  const textStyle = {
    fontSize: 14,
    color: '#6c6c6a',
  }

  const noteStyle = {
    fontSize: 10,
    color: '#6c6c6a',
    padding: '0 10px',
  }

  const errorStyle = {
    fontSize: 12,
    padding: '0 10px 10px',
  }

  return (
    <SecondaryLayout
      showTabs
      toolbar={
        <SecondaryToolbar className="secondary-toolbar">
          <IonButtons slot="start">
            <IonBackButton color="dark" text="" />
          </IonButtons>
          <IonTitle>
            Aggiungi libro
          </IonTitle>
        </SecondaryToolbar>
      }
    >
      <IonRow>
        <IonCol size="5">
          <IonRow>
            <img height={'200px'} src={newBook?.volumeInfo?.imageLinks?.thumbnail ? newBook?.volumeInfo?.imageLinks?.thumbnail : '/images/missing-book.png'} />
          </IonRow>
        </IonCol>
      </IonRow>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 100 }}>
        <div style={{ marginBottom: 20 }}>
          <IonLabel style={labelStyle}><strong>TITOLO</strong></IonLabel>
          <IonInput size={2} style={inputStyle} {...register('volumeInfo.title')} placeholder="Titolo" />
          { errors.volumeInfo?.title ? <div><IonText color="danger" style={errorStyle}>{ errors.volumeInfo?.title.message }</IonText></div> : null }
        </div>

        <div style={{ marginBottom: 20 }}>
          <IonLabel style={labelStyle}>AUTORE / AUTRICE</IonLabel>
          <IonInput style={inputStyle} {...register('volumeInfo.authors')} placeholder="Autore / Autrice" />
          <IonNote style={noteStyle}>Separa gli autori con una virgola</IonNote>
          { errors.volumeInfo?.authors ? <div><IonText color="danger" style={errorStyle}>{ errors.volumeInfo?.authors.message }</IonText></div> : null }
        </div>

        <div style={{ marginBottom: 20 }}>
          <IonLabel style={labelStyle}>ANNO DI PUBBLICAZIONE</IonLabel>
          <IonInput style={inputStyle} {...register('volumeInfo.publishedDate')} placeholder="aaaa" />
          { errors.volumeInfo?.publishedDate ? <div><IonText color="danger" style={errorStyle}>{ errors.volumeInfo?.publishedDate.message }</IonText></div> : null }
        </div>

        <div style={{ marginBottom: 20 }}>
          <IonLabel style={labelStyle}>CASA EDITRICE</IonLabel>
          <IonInput style={inputStyle} {...register('volumeInfo.publisher')} placeholder="Casa Editrice" />
          { errors.volumeInfo?.publisher ? <div><IonText color="danger" style={errorStyle}>{ errors.volumeInfo?.publisher.message }</IonText></div> : null }
        </div>
      </form>

      <IonFab horizontal="end" slot="fixed" style={{ bottom: 63 }} vertical="bottom">
        <IonFabButton color="primary" onClick={openModal} >
          <IonIcon icon={checkmarkOutline} />
        </IonFabButton>
      </IonFab>
      <IonModal className="book-confirmation-modal" isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonGrid style={{ padding: '20px 15px' }}>
          <IonRow>
            <IonCol size="3">
              <img src={newBook?.volumeInfo?.imageLinks?.thumbnail ? newBook?.volumeInfo?.imageLinks?.thumbnail : '/images/missing-book.png'} />
            </IonCol>
            <IonCol>
              <IonRow>
                { getValues('volumeInfo.title') && <IonText style={{ fontWeight: 'bold' }}>{ getValues('volumeInfo.title') }</IonText> }
              </IonRow>
              <IonRow>
                { getValues('volumeInfo.authors') && <IonText style={textStyle}>{ getValues('volumeInfo.authors') }</IonText> }
              </IonRow>
              <IonRow>
                { getValues('volumeInfo.publisher') && <IonText style={textStyle}>{ getValues('volumeInfo.publisher') }</IonText> }
              </IonRow>
              <IonRow>
                { getValues('volumeInfo.publishedDate') && <IonText style={textStyle}>{ formatDate(getValues('volumeInfo.publishedDate')) }</IonText> }
              </IonRow>
            </IonCol>
          </IonRow>
          <hr style={{ backgroundColor: '#c0c0c0', margin: 7, height: 3 }} />
          <IonRow class="ion-justify-content-center">
            <IonText class="ion-text-center">
              È tutto giusto?
            </IonText>
          </IonRow>
          <IonRow class="ion-justify-content-center">
            <IonButton onClick={handleSubmit(onSubmit)} shape="round">
              Si
            </IonButton>
            <IonButton color="light-grey" onClick={() => setShowModal(false)} shape="round">
              No
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonModal>
    </SecondaryLayout>
  )
}

export default Review