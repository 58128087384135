import {
  IonTitle,
  IonIcon,
  IonButton,
  IonBackButton,
  IonButtons,
  IonList,
  useIonAlert,
  IonLabel,
  IonToggle,
  IonItem,
  IonSpinner,
} from '@ionic/react'
import axios from 'axios'
import { chevronForwardOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { signOut } from '../plugins/firebase'
import { useAuth } from '../providers/AuthProvider'
import { useToast } from '../providers/ToastProvider'
import SecondaryLayout from '../layout/SecondaryLayout'
import SecondaryToolbar from '../components/SecondaryToolbar'
import { useMessaging } from '../providers/FirebaseMessagingProvider'
import { Browser } from '@capacitor/browser'

const Settings: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [logoutLoading, setLogoutLoading] = useState(false)
  const [vacation, setVacation] = useState(false)
  const history = useHistory()
  const toast = useToast()
  const [present] = useIonAlert()
  const { user, b2bUser, reset: resetUserData } = useAuth()
  const { resetFCMToken } = useMessaging()

  const deleteUser = async () => {
    setLoading(true)

    try {
      await axios.delete('/user')
    } catch (error) {
      console.error(error)
      toast.error('Errore nel eliminazione dell\' utente')
    }

    setLoading(false)

    //logout user after delete
    await signOut()
    history.replace('/login')
    toast.success('Utente eliminato con successo')
  }

  const signOutAndRedirect = async () => {
    setLogoutLoading(true)
    await resetFCMToken()

    await signOut()
    await resetUserData()

    history.replace('/login', { loginModalOpen: false })
    setLogoutLoading(false)
  }

  const changeVacationStatus = async () => {
    setLoading(true)

    try {
      await axios.post('/user/vacation', { vacation })
    } catch (error) {
      console.error(error)
      toast.error('Errore nel cambio di stato della modalità vacanza')
    }

    setLoading(false)
  }

  const loadVacation = async () => {
    try {
      const { data } = await axios.get('user')
      setVacation(data.vacation)
    } catch (error) {
      console.error(error)
      toast.error('Errore nel recupero delle info utente')
    }
  }

  const openPrivacyAndPolicyLink = async () => {
    await Browser.open({ url: 'https://book2book.org/privacy-policy' })
  }

  useEffect(() => {
    let isMounted = true
    if (user && isMounted)
      loadVacation()
  }, [b2bUser])

  useEffect(() => {
    if (user)
      changeVacationStatus()
  }, [vacation])

  return (
    <SecondaryLayout
      loading={loading}
      showTabs
      toolbar={
        <SecondaryToolbar>
          <IonButtons slot="start">
            <IonBackButton color="light" text="" />
          </IonButtons>
          <IonTitle>
            Impostazioni
          </IonTitle>
        </SecondaryToolbar>

      }
    >
      <IonList>
        <IonItem lines="none">
          <IonLabel>Modalità vacanza</IonLabel>
          <IonToggle checked={vacation} onIonChange={(e) => { setVacation(e.detail.checked) }} />
        </IonItem>
        <IonButton
          class="ion-margin-top ion-margin-bottom"
          color="secondary"
          expand="full"
          onClick={openPrivacyAndPolicyLink}
          shape="round"
        >
          Privacy e condizioni di utilizzo
          <IonIcon icon={chevronForwardOutline} slot="end" />
        </IonButton>

        <IonButton
          class="ion-margin-top ion-margin-bottom"
          color="secondary"
          expand="full"
          href="https://forms.gle/f5z2dXYum8dC22Mx9"
          shape="round"
          target="_blank"
        >
          Segnala un problema / supporto
          <IonIcon icon={chevronForwardOutline} slot="end" />
        </IonButton>

        <IonButton
          class="ion-margin-top ion-margin-bottom"
          color="medium"
          expand="full"
          onClick={() => {
            present({
              header: 'Confermi di voler eliminare il tuo account?',
              buttons: [
                'Annulla',
                {
                  text: 'Elimina', handler: () => deleteUser(),
                },
              ],
            })
          }}
          shape="round"
        >
          Elimina il tuo account
        </IonButton>

        <IonButton
          class="ion-margin-top ion-margin-bottom"
          color="medium"
          expand='block'
          onClick={signOutAndRedirect}
          shape="round"
        >
          { logoutLoading ?
            <IonSpinner name="dots" />
            :
            'LOGOUT' }

        </IonButton>
        <IonButton
          class="ion-margin-top ion-margin-bottom"
          color="danger"
          expand='block'
          onClick={() => { history.push('/profile/settings/tutorial') }}
          shape="round"
        >
          Tutorial
        </IonButton>
      </IonList>
    </SecondaryLayout>
  )
}

export default Settings