import { IonProgressBar } from '@ionic/react'
import { LoadingProps } from '~types/components'

const Loading: React.FC<LoadingProps> = ({ isLoading }: LoadingProps) => {
  return (
    <>
      { isLoading ?
        <IonProgressBar
          type="indeterminate"
        /> : null }
    </>
  )
}

export default Loading