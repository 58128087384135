import {
  IonToolbar,
  IonSearchbar,
  IonIcon,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import { funnel, funnelOutline } from 'ionicons/icons'
import { BookPointToolbarProps } from '~types/components'

const BookPointToolbar: React.FC<BookPointToolbarProps> = ({ search, setSearch, filter, setFilter, selectedSegment, setSelectedSegment }: BookPointToolbarProps) => {
  const funnelIconStyle = {
    fontSize: 24,
    paddingLeft: 3,
    paddingRight: 10,
  }

  return (
    <>
      <IonToolbar class="light-toolbar">
        <div style={{ display: 'flex', alignItems: 'center', height: 60 }}>
          <IonSearchbar
            debounce={750}
            onIonChange={e => {
              setSearch(e.detail.value!)
            }}
            placeholder="Cerca tra i BookPoint"
            showCancelButton="never"
            style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 0 }}
            value={search}
          />
          <IonIcon
            color={filter.opened || filter.has_shelfs ? 'primary' : 'grey'}
            icon={filter.opened || filter.has_shelfs ? funnel : funnelOutline}
            id="trigger-filter-bookpoints"
            slot="end"
            style={funnelIconStyle}
          />
          <IonPopover trigger="trigger-filter-bookpoints">
            <IonContent class="ion-text-center" scrollY={false}>
              <IonList class="ion-no-padding">
                <IonItem lines='full'>
                  <IonLabel>
                    <small>Aperti Ora</small>
                  </IonLabel>
                  <IonCheckbox
                    checked={filter?.opened === 1}
                    color="primary"
                    onIonChange={(e) => { setFilter({ ...filter, opened: e.detail.checked ? 1 : 0 }) }}
                    slot="end"
                  />
                </IonItem>
                <IonItem lines='none'>
                  <IonLabel>
                    <small>Ha libri Disponibili</small>
                  </IonLabel>
                  <IonCheckbox
                    checked={filter?.has_shelfs === 1}
                    color="primary"
                    onIonChange={(e) => { setFilter({ ...filter, has_shelfs: e.detail.checked ? 1 : 0 }) }}
                    slot="end"
                  />
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>
        </div>
      </IonToolbar>
      <IonToolbar
        class="light-toolbar bookpoints-toolbar"
        style={{ height: 45, minHeight: 45 }}
      >
        <IonSegment
          onIonChange={(val) => setSelectedSegment(val.detail.value)}
          style={{ margin: '0px 10px', width: '95%' }}
          value={selectedSegment}
        >
          <IonSegmentButton
            style={{ height: 35, minHeight: 35 }}
            value="list" >
            Lista
          </IonSegmentButton>
          <IonSegmentButton
            style={{ height: 35, minHeight: 35 }}
            value="map"
          >
            Mappa
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>
    </>
  )
}

export default BookPointToolbar
