import {
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRow,
  IonSearchbar,
  IonSkeletonText,
  IonText,
  IonToolbar,
  ScrollDetail,
  useIonViewDidEnter,
} from '@ionic/react'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Swiper, SwiperSlide } from 'swiper/react'
import BookCard from '../components/BookCard'
import Carousel from '../components/Carousel'
import { useToast } from '../providers/ToastProvider'
import { Geolocation, Position } from '@capacitor/geolocation'
import { shelfsWithDistance } from '../plugins/distance'
import HomeLayout from '../layout/HomeLayout'
import { useDevice } from '../providers/DeviceProvider'
import ToastTutorial from '../components/ToastTutorial'
import { useTabs } from '../components/Main'
import { useDebounce } from 'usehooks-ts'
import { funnelOutline } from 'ionicons/icons'
import UserCard from '../components/UserCard'
import { Device } from '@capacitor/device'

export type BookPointFilterParams = {
  books: number,
  users: number,
}

const Home: React.FC = () => {
  const defaultFilter: BookPointFilterParams = {
    books: 1,
    users: 1,
  }

  const debounce = 750
  const [search, setSearch] = useState<string>('')
  const debouncedValue = useDebounce<string>(search, debounce)
  const [loading, setLoading] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [noShelfs, setNoShelfs] = useState(false)
  const [position, setPosition] = useState<Position | null | undefined>(undefined)
  const [bookId, setBookId] = useState<any>(null)
  const [carousels, setCarousels] = useState<any>([])
  const [toolbarHeight, setToolbarHeight] = useState<number>(100)
  const [shrunk, setShrunk] = useState(false)
  const [filter, setFilter] = useState(defaultFilter)
 
  const [shelfs, setShelfs] = useState<any[]>([])
  const [page, setPage] = useState({ page: 1 })
  const [infiniteEvent, setInfiniteEvent] = useState<any>()
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false)

  const [users, setUsers] = useState<any[]>([])
  const [usersPage, setUsersPage] = useState({ page: 1 })
  const [usersInfiniteEvent, setUsersInfiniteEvent] = useState<any>()
  const [disableUsersInfiniteScroll, setDisableUsersInfiniteScroll] = useState(false)

  const filteredShelfs = useMemo(() => {
    return shelfs.map((b: any) => {
      const group = b.shelfs.length > 1
      let ret = shelfsWithDistance(b.shelfs, position)
      const books = ret.map((s: any) => {
        return { ...b, shelf_id: s.id, status: s.status, borrow: s.borrow, host: s.user, distance: s.distance }
      })
      return {
        group,
        books,
      }
    })
  }, [shelfs, position])

  const history: any = useHistory()
  const toast = useToast()
  const { isAndroid, isWeb } = useDevice()
  const { homeClicked, setHomeClicked } = useTabs()

  const setCurrentPosition = async () => {
    try {
      const deviceInfo = await Device.getInfo()
      const isMobile = deviceInfo.platform && (['ios', 'android'].includes(deviceInfo.platform))

      if (!isMobile) {
        setPosition(await Geolocation.getCurrentPosition())  
      } else {
        const permissions = await Geolocation.requestPermissions()
      
        if(permissions.location === 'granted') {
          setPosition(await Geolocation.getCurrentPosition())    
        }
      }
    } catch (error: any) {
      // 1 -> permission denied => l'utente non ha accettato di condividere la posizione
      if (error.code !== 1) {
        console.error(error)
        // toast.error('Errore nel recupero della posizione')
      }

      setPosition(null)
    }
  }

  const loadCarousel = async () => {
    setLoading(true)

    try {
      const { data } = await axios.get('carousels')
      setCarousels(data)
    } catch (error) {
      console.error(error)
      toast.error('Verifica la tua connessione a internet.')
    }

    setLoading(false)
  }

  const loadData = async () => {
    const promises = []
    if(filter.books) {
      promises.push(loadShelfs(true))
    }
    if(filter.users) {
      promises.push(loadUsers(true, filter.books ? true : false))
    }
    await Promise.all(promises)
  }

  const loadUsers = async (refresh = false, limit = false) => {
    setLoading(true)

    try {
      const { data, meta }: any = await axios.get('users', {
        params: {
          limit: limit ? 3 : null,
          search,
          page: usersPage.page,
        },
      })

      setUsers(refresh ? data : [...users, ...data])
      if(filter.books === 0)
        setDisableUsersInfiniteScroll(data.length < meta.limit || data.length === 0)
    } catch (error) {
      console.error(error)
      toast.error('Errore nel caricamento degli utenti')
    }

    usersInfiniteEvent?.target?.complete()

    setLoading(false)
  }

  const loadMoreUsers = (event: any) => {
    setUsersInfiniteEvent(event)
    setUsersPage({ page: usersPage.page + 1 })
  }

  const loadShelfs = async (refresh = false) => {
    setLoading(true)

    try {
      const { data, status, meta }: any = await axios.get('books', {
        params: {
          search,
          page: page.page,
          book_id: bookId,
        },
      })
      // non ho trovato nessuno shelf per questo libro
      if (status === 206) {
        setNoShelfs(true)
        setDisableInfiniteScroll(true)
      } else if (status === 200) {
        setNoShelfs(false)
      }

      setBookId(null)
      setShelfs(refresh ? data : [...shelfs, ...data])
      setDisableInfiniteScroll(data.length < meta.limit || data.length === 0)
    } catch (error) {
      console.error(error)
      toast.error('Errore nel caricamento dei libri')
    }

    infiniteEvent?.target?.complete()

    setLoading(false)
  }

  const loadMoreShelfs = (event: any) => {
    if (!noShelfs) {
      setInfiniteEvent(event)
      setPage({ page: page.page + 1 })
    }
  }

  const refresh = async () => {
    setShelfs([])
    setPage({ page: 1 })
  }

  const handleClear = () => {
    setSearch('')
  }

  useEffect(() => {
    loadShelfs()
  }, [page.page])

  useEffect(() => {
    loadUsers()
  }, [usersPage.page])

  useEffect(() => {
    if (homeClicked) {
      setSearch('')
      setHomeClicked(false)
    }
  }, [homeClicked])

  useEffect(() => {
    refresh()
    if (search === '') {
      loadCarousel()
    } else if (search !== '') {
      loadData()
    }

    if (search.length !== 0 && toolbarHeight !== 0) {
      setToolbarHeight(0)
      setShrunk(true)
    } else if (search.length === 0 && toolbarHeight === 0 && window.innerWidth < window.innerHeight && window.innerWidth < 650) {
      setToolbarHeight(100)
      setShrunk(false)
    }
    setNoShelfs(false)
  }, [debouncedValue, filter])

  useEffect(() => {
    if (!position)
      setCurrentPosition()
  }, [position])

  useIonViewDidEnter(() => {
    // for search from starred books click 
    if (history.location.state && history.location.state.search) {
      setSearch(history.location.state.search)
      let state = { ...history.location.state }
      delete state.search
    }

    if (history.location.state && history.location.state.showBannerTutorial) {
      setShowBanner(true)
      let state = { ...history.location.state }
      delete state.showBannerTutorial
    }

    if (window.innerWidth > window.innerHeight || window.innerWidth > 650) {
      setToolbarHeight(0)
      setShrunk(true)
    }
  })

  const carouselCardClicked = (book: any) => {
    setBookId(book.id)
    setSearch(book.title)
  }

  const toolbarHomeHeight = () => {
    if(isWeb || isAndroid) {
      return 60
    }
    return 'calc(var(--ion-safe-area-top, 0) + 60px)'
  }

  const searchBarStyle = {
    background: 'light',
    borderRadius: 20,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    height: 60,
  }

  const infoCardStyle = {
    color: 'info',
    padding: 10,
    marginLeft: 13,
    marginRight: 13,
    marginTop: 13,
  }

  const searchTypeIconStyle = {
    fontSize: 24,
    paddingLeft: 3,
    paddingRight: 10,
  }

  const toolbarHomeStyle = {
    height: toolbarHomeHeight(),
    paddingBottom: 10,
    borderRadius: '0px 0px 20px 20px',
    // paddingTop: `max(var(--ion-safe-area-top, 0), ${statusBarHeight}px)`
  }

  const titleStyle = {
    textTransform: 'uppercase',
    margin: '0px 15px',
    padding: '0px',
    fontSize: 15,
  }

  const changeToolbarHeight = (event: CustomEvent<ScrollDetail>) => {
    if (search.length === 0 && window.innerWidth < 650) {
      if (event.detail.deltaY > 0 && event.detail.currentY > 60) {
        setToolbarHeight(0)
        setShrunk(true)
      } else if (event.detail.deltaY < 0 && event.detail.currentY < 60) {
        setToolbarHeight(100)
        setShrunk(false)
      }
    }
  }

  return (
    <HomeLayout onIonScroll={changeToolbarHeight} search={search} toolbar={
      <>
        <IonToolbar class="ion-no-border" style={toolbarHomeStyle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonSearchbar
              debounce={debounce}
              onIonClear={handleClear}
              onIonInput={e => {
                setSearch(e.target.value!)
              }}
              placeholder="Cerca un libro o un autore..."
              style={searchBarStyle}
              value={search}
            />
            <IonIcon
              icon={funnelOutline}
              id="trigger-filter-home"
              slot="end"
              style={searchTypeIconStyle}
            />
            <IonPopover trigger="trigger-filter-home">
              <IonContent class="ion-text-center" scrollY={false}>
                <IonList class="ion-no-padding">
                  <IonItem lines='full'>
                    <IonLabel>
                      <small>Ricerca libri</small>
                    </IonLabel>
                    <IonCheckbox
                      checked={filter?.books === 1}
                      color="primary"
                      onIonChange={(e) => { setFilter({ ...filter, books: e.detail.checked ? 1 : 0 }) }}
                      slot="end"
                    />
                  </IonItem>
                  <IonItem lines='none'>
                    <IonLabel>
                      <small>Ricerca utenti</small>
                    </IonLabel>
                    <IonCheckbox
                      checked={filter?.users === 1}
                      color="primary"
                      onIonChange={(e) => { setFilter({ ...filter, users: e.detail.checked ? 1 : 0 }) }}
                      slot="end"
                    />
                  </IonItem>
                </IonList>
              </IonContent>
            </IonPopover>
          </div>
        </IonToolbar>
        <div className="toolbar-home" style={{ height: toolbarHeight, transition: '0.3s', overflow: shrunk ? 'hidden' : 'visible' }}>
          <div className="toolbar-home-background" />
          <IonImg src="images/personaggi-homepage.svg" style={{ opacity: shrunk ? 0 : 1, transition: '0.3s', width: '42%', margin: '0px auto', position: 'relative' }} />
        </div>
      </>
    }>
      { !loading ?
        <>
          {
            search.length === 0 ?
              <IonGrid class="ion-no-padding" style={{ paddingTop: 53, paddingBottom: 30 }}>
                { carousels.daily ? <Carousel books={carousels.daily} cardClick={carouselCardClicked} title="I libri del giorno" /> : null }
                { carousels.most_loved ? <Carousel books={carousels.most_loved} cardClick={carouselCardClicked} title="I più popolari" /> : null }
                { carousels.bookpoints ? <Carousel books={carousels.bookpoints} cardClick={carouselCardClicked} title="Novità dei BookPoint" /> : null }
                { carousels.starred ? <Carousel books={carousels.starred} cardClick={carouselCardClicked} title="I più scambiati" /> : null }
                { carousels.short ? <Carousel books={carousels.short} cardClick={carouselCardClicked} title="Libri di breve lettura" /> : null }
                { carousels.long ? <Carousel books={carousels.long} cardClick={carouselCardClicked} title="Libri di lunga lettura" /> : null }
              </IonGrid>
              :
              <IonGrid class="ion-no-padding" style={{ paddingTop: 33, paddingBottom: 30 }}>
                { filter.users === 1 && users.length !== 0 && (
                  <>
                    <IonCol size="12" style={{ padding: '3px 10px 0px' }}>
                      <IonText style={titleStyle} ><strong>Utenti</strong></IonText>
                    </IonCol>
                    { users.map((user: any) => {
                      return (
                        <UserCard key={user.id} user={user} />
                      )
                    }) }
                    { filter.books === 0 && (
                      <IonInfiniteScroll
                        disabled={disableUsersInfiniteScroll}
                        onIonInfinite={loadMoreUsers}
                        threshold="1%"
                      >
                        <IonInfiniteScrollContent
                          loadingSpinner="dots"
                          loadingText="Caricamento in corso..."
                        />
                      </IonInfiniteScroll>
                    ) }
                  </>
                ) }

                { filter.books === 1 && (
                  <>
                    <IonCol size="12" style={{ padding: '3px 10px 0px' }}>
                      <IonText style={titleStyle} ><strong>Libri</strong></IonText>
                    </IonCol>
                    <>
                      { noShelfs ? <IonCard class="ion-text-center" color="info" style={infoCardStyle}>
                        <IonText>Questo libro non è più disponibile in nessuna libreria, però potrebbero interessarti questi altri libri:</IonText>
                      </IonCard> : null }
                      { filteredShelfs.map((b: any, x: number) => {
                        if (b.group) {
                          return (
                            <Swiper key={x} slidesPerView={1.2} spaceBetween={10} style={{ paddingLeft: 13 }}>
                              {
                                b.books.map((e: any, i: number) => {
                                  return (<SwiperSlide key={i}>
                                    <BookCard
                                      book={e}
                                      isInLibrary={false}
                                      onClick={() => history.push(`/hostShelf/${e.shelf_id}`)}
                                      style={{ margin: 0 }}
                                    />
                                  </SwiperSlide>)
                                })
                              }
                            </Swiper>)
                        } else {
                          return (<BookCard
                            book={b.books[0]}
                            isInLibrary={false}
                            key={x}
                            onClick={() => history.push(`/hostShelf/${b.books[0].shelfs[0].id}`)}
                          />)
                        }}) }
                    </> 
                  </>
                ) }
                <IonInfiniteScroll
                  disabled={disableInfiniteScroll}
                  onIonInfinite={loadMoreShelfs}
                  threshold="1%"
                >
                  <IonInfiniteScrollContent
                    loadingSpinner="dots"
                    loadingText="Caricamento in corso..."
                  />
                </IonInfiniteScroll>
              </IonGrid>
          }
        </>
        :
        <IonGrid class="ion-no-padding" style={{ paddingTop: 33, paddingBottom: 30 }}>
          {
            Array.from({ length: 4 }, (_, index) =>
              (<IonRow key={index} style={{ margin: '0px 12px 13px' }}>
                <IonSkeletonText animated class="ion-no-margin" style={{ height: 160, borderRadius: 30 }} />
              </IonRow>),
            )
          }
        </IonGrid> }

      <ToastTutorial
        setShowBanner={setShowBanner}
        showBanner={showBanner}
      />
    </HomeLayout >
  )
}

export default Home
