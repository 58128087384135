import { IonCard, IonCardContent, IonCol, IonGrid, IonRow, IonSkeletonText } from '@ionic/react'

const BookPointDetailsLoading: React.FC = () => {
  return (
    <IonCard class="ion-no-margin" style={{ borderRadius: '20px 20px 0px 0px' }}>
      <IonSkeletonText animated class="ion-no-margin" style={{ height: 341, width: '100%' }} />
      <IonCardContent style={{ padding: 0 }}>
        <IonGrid style={{ padding: '10px 0px' }}>
          <IonRow>
            <IonCol class="ion-no-padding" size="9">
              <IonRow class="ion-align-items-center" >
                <IonSkeletonText animated class="ion-no-margin" style={{ height: 23, width: '100%', marginBottom: 4 }} />
                <IonSkeletonText animated class="ion-no-margin" style={{ height: 23, width: '100%', marginBottom: 4 }} />
                <IonSkeletonText animated class="ion-no-margin" style={{ height: 23, width: '100%', marginBottom: 4 }} />
              </IonRow>
            </IonCol>
          </IonRow>
          <IonSkeletonText animated class="ion-no-margin" style={{ height: 40, width: '100%', marginTop: 4, borderRadius: '0px 10px 10px' }} />
        </IonGrid>
        <IonGrid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <IonRow>
            <IonCol size="6">
              <IonRow>
                <IonCol class="ion-no-padding" size="2">
                  <IonSkeletonText animated  style={{ height: 23, width: 16, marginBottom: 4,marginRight: 4 }} />
                </IonCol>
                <IonCol class="ion-no-padding" size="10">
                  <IonSkeletonText animated  style={{ height: 23,width: '100%', marginBottom: 4,marginRight: 4 }} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol class="ion-no-padding" size="2">
                  <IonSkeletonText animated  style={{ height: 23, width: 16, marginBottom: 4,marginRight: 4 }} />
                </IonCol>
                <IonCol class="ion-no-padding" size="10">
                  <IonSkeletonText animated  style={{ height: 23,width: '100%', marginBottom: 4,marginRight: 4 }} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol class="ion-no-padding" size="2">
                  <IonSkeletonText animated  style={{ height: 23, width: 16, marginBottom: 4,marginRight: 4 }} />
                </IonCol>
                <IonCol class="ion-no-padding" size="10">
                  <IonSkeletonText animated  style={{ height: 23,width: '100%', marginBottom: 4,marginRight: 4 }} />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="6">
              <IonRow>
                <IonCol class="ion-no-padding" size="2">
                  <IonSkeletonText animated  style={{ height: 23, width: 16, marginBottom: 4,marginRight: 4 }} />
                </IonCol>
                <IonCol class="ion-no-padding" size="10">
                  <IonSkeletonText animated  style={{ height: 23,width: '100%', marginBottom: 4,marginRight: 4 }} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol class="ion-no-padding" size="2">
                  <IonSkeletonText animated  style={{ height: 23, width: 16, marginBottom: 4,marginRight: 4 }} />
                </IonCol>
                <IonCol class="ion-no-padding" size="10">
                  <IonSkeletonText animated  style={{ height: 23,width: '100%', marginBottom: 4,marginRight: 4 }} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol class="ion-no-padding" size="2">
                  <IonSkeletonText animated  style={{ height: 23, width: 16, marginBottom: 4,marginRight: 4 }} />
                </IonCol>
                <IonCol class="ion-no-padding" size="10">
                  <IonSkeletonText animated  style={{ height: 23,width: '100%', marginBottom: 4,marginRight: 4 }} />
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  )
}

export default BookPointDetailsLoading