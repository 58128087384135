import { IonCol, IonRow, IonText } from '@ionic/react'
import BookCard from './BookCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { CarouselProps } from '~types/components'

const Carousel: React.FC<CarouselProps> = ({ title, books, cardClick }: CarouselProps) => {
  const titleStyle = {
    textTransform: 'uppercase',
    margin: '0px 15px',
    padding: '0px',
    fontSize: 15,
  }

  return (
    <div style={{ marginTop: 5 }}>
      <IonRow>
        <IonCol size="12" style={{ padding: '3px 10px 0px' }}>
          <IonText style={titleStyle} ><strong>{ title }</strong></IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol class="ion-no-padding" size="12">
          <Swiper slidesPerView={1.2} spaceBetween={0}>
            {
              books.map((b: any, x: number) =>
                (<SwiperSlide key={x}>
                  <BookCard book={b} onClick={() => cardClick(b)} style={{ maxHeight: 130 }} />
                </SwiperSlide>),
              )
            }
          </Swiper>
        </IonCol>
      </IonRow>
    </div>
  )
}

export default Carousel