import ErrorPage from './ErrorPage'
import { ErrorBoundary } from 'react-error-boundary'
import { PropsWithChildren } from 'react'

const ReactErrorBoundary: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<any>) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error, errorInfo) => {
        console.error(error)
        console.error(errorInfo)
      }}
    >
      { children }
    </ErrorBoundary>
  )
}

export default ReactErrorBoundary