import {
  IonGrid,
  IonText,
  IonModal,
  IonContent,
} from '@ionic/react'
import { QRCodeCanvas } from 'qrcode.react'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import { borrowStatuses } from '../enums/BorrowStatuses'
import { useToast } from '../providers/ToastProvider'
import { QRCodeModalProps } from '~types/components'

const QRCodeModal: React.FC<QRCodeModalProps> = ({ isOpen, borrow, isHost, onDidDismiss, onRead }: QRCodeModalProps) => {
  const toast = useToast()

  const renderQRModal = () => {
    if ((isHost && borrow.status === borrowStatuses.sync_pending) || (!isHost && borrow.status === borrowStatuses.active)) {
      return (
        <IonGrid>
          <BarcodeScannerComponent
            onError={(error: any) => {
              console.error(error)
              toast.error('Impossibile leggere il QRCode: ' + error)
            }}
            
            onUpdate={(_, result: any) => {
              if (result)
                onRead(result.text)
            }}
            width="100%"
          />
        </IonGrid>
      )
    } else if ((isHost && borrow.status === borrowStatuses.active) || (!isHost && borrow.status === borrowStatuses.sync_pending)) {
      return (
        <IonText onClick={onDidDismiss} style={{ verticalAlign: 'middle' }}>
          <QRCodeCanvas
            includeMargin
            size={(window.innerWidth * 90) / 100}
            value={borrow.qrcode}
          />
        </IonText>
      )
    }
  }

  return (
    <IonModal
      className="sync-borrow-modal"
      isOpen={isOpen}
      onClick={onDidDismiss}
      onDidDismiss={onDidDismiss}
    >
      <IonContent class="ion-text-center" scrollY={false}>
        { renderQRModal() }
      </IonContent>
    </IonModal>
  )
}

export default QRCodeModal