// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { FirebaseAuthentication, User } from '@capacitor-firebase/authentication'

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
}

// Initialize Firebase
initializeApp(firebaseConfig)

// auth.setPersistence(browserSessionPersistence)

export const onAuthStateChanged = (fn: (user: User | null) => void) => {
  FirebaseAuthentication.addListener('authStateChange', ({ user }) => {
    fn(user)
  })
}

export const removeListeners = () => {
  FirebaseAuthentication.removeAllListeners()
}

export const getCurrentUser = async () => {
  const result = await FirebaseAuthentication.getCurrentUser()
  return result.user
}

export const getIdToken = async () => {
  return await FirebaseAuthentication.getIdToken({ forceRefresh: true }).then(r => r.token).catch(() => undefined)
}

export const signInWithGoogle = async () => {
  const result = await FirebaseAuthentication.signInWithGoogle()
  return result.user
}

export const signInWithApple = async () => {
  const result = await FirebaseAuthentication.signInWithApple()
  return result.user
}

export const signInWithEmail = async (email: string, password: string) => {
  const result = await FirebaseAuthentication.signInWithEmailAndPassword({
    email,
    password,
  })
  return result.user
}

export const signOut = async () => {
  await FirebaseAuthentication.signOut()
}