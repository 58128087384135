import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonText,
  IonTitle,
  useIonAlert,
  useIonViewDidEnter,
} from '@ionic/react'
import axios from 'axios'
import { format, parseISO } from 'date-fns'
import { it } from 'date-fns/locale'
import {
  chevronForwardOutline,
  heartCircle,
  lockClosed,
  lockOpen,
  trashOutline,
} from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
// import Score from '../components/Score'
import { useToast } from '../providers/ToastProvider'
import { shelfStatuses } from '../enums/ShelfStatuses'
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker'
import { Cookies } from 'react-cookie'
import { useAuth } from '../providers/AuthProvider'
import { useDevice } from '../providers/DeviceProvider'
import SecondaryLayout from '../layout/SecondaryLayout'
import SecondaryToolbar from '../components/SecondaryToolbar'

const BookDetails: React.FC<RouteComponentProps> = ({ match }: RouteComponentProps) => {
  const [descriptionActive, setDescriptionActive] = useState<boolean>(true)
  const [shelf, setShelf] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [isHost, setIsHost] = useState(false)
  const [present] = useIonAlert()
  const history = useHistory()
  const toast = useToast()
  const { b2bUser } = useAuth()
  const { isWeb, isAndroid } = useDevice()
  const gaEventTracker = useAnalyticsEventTracker('Book')
  const cookies = new Cookies()
  const params: any = match.params

  const changeStatus = async () => {
    //se il libro è stato dato in prestito non posso modificare pubblico o privato
    if (shelf.status !== shelfStatuses.loaned) {
      try {
        setLoading(true)
        if (isHost && shelf.status === shelfStatuses.public) {
          await axios.post(`/shelfs/${params.id}/private`)
          shelf.status = shelfStatuses.private
        }
        else {
          await axios.post(`/shelfs/${params.id}/public`)
          shelf.status = shelfStatuses.public
        }
        localStorage.setItem('refresh_shelf', 'true')
      } catch (error) {
        console.error(error)
        toast.error('Errore nell\'aggiornamento dello stato')
      } finally {
        setLoading(false)
      }
    }
  }

  const changeStarred = async () => {
    //se non sono l'host posso modificare lo starred
    if (!isHost) {
      try {
        setLoading(true)
        if (shelf.book?.starred) {
          await axios.post(`/books/${shelf.book.id}/unstarred`)
          shelf.book.starred = false
        }
        else {
          await axios.post(`/books/${shelf.book.id}/starred`)
          shelf.book.starred = true
        }
      } catch (error) {
        console.error(error)
        toast.error('Errore nell\'aggiornamento del preferito')
      } finally {
        setLoading(false)
      }
    }
  }

  // const changeScore = async (score: number) => {
  //   if (!isHost) {
  //     setLoading(true)

  //     try {
  //       await axios.post(`/books/${shelf.book.id}/score`, { score })
  //       setShelf({ ...shelf, book: { ...shelf.book, score } })
  //     } catch (error) {
  //       console.error(error)
  //       toast.error('Errore nell\'aggiornamento dello score')
  //     }

  //     setLoading(false)
  //   }
  // }

  const deleteShelf = async () => {
    //se il libro è stato dato in prestito non posso eliminarlo
    if (shelf.status !== shelfStatuses.loaned) {
      try {
        setLoading(true)

        await axios.delete(`/shelfs/${params.id}`)
        history.replace('/myShelf', { refresh_shelf: true })
      } catch (error) {
        console.error(error)
        toast.error('Errore nella cancellazione del libro')
      } finally {
        setLoading(false)
      }
    }
  }

  const book = async () => {
    // se il libro non è pubblico non posso prenotarlo
    if (shelf.status !== shelfStatuses.private) {
      try {
        setLoading(true)
        const { data } = await axios.post(`/shelfs/${params.id}/book`)
        if (cookies.get('user-has-accepted-cookies')) {
          gaEventTracker('Start New Borrow')
        }
        history.replace(`/chats/${data.id}`)
      } catch (error: any) {
        console.error(error)
        toast.error(error.response.data.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const formatDate = (value: string, dateFormat: string = 'dd MMM yyyy') => {
    if (value)
      return format(parseISO(value), dateFormat, { locale: it })
  }

  const redirectToHostDetails = () => {
    if (shelf.status === shelfStatuses.borrowed) {
      if (shelf.borrow.shelf.user.bookpoint) {
        history.push(`/bookPoints/${shelf.borrow.shelf.user?.id}`)
      } else {
        history.push(`/user/${shelf.borrow.shelf.user?.id}`)
      }
    } else {
      if (shelf.user.bookpoint) {
        history.push(`/bookPoints/${shelf.user?.id}`)
      } else {
        history.push(`/user/${shelf.user?.id}`)
      }
    }
  }

  const loadBookDetails = async () => {
    try {
      const { data } = await axios.get(`/shelfs/${params.id}`)
      setShelf(data)
    } catch (error) {
      console.error(error)
      toast.error('Errore nel recupero del dettaglio libro')
    }
  }

  const loadData = async () => {
    setLoading(true)
    await loadBookDetails()
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [params.id])

  useEffect(() => {
    if (b2bUser && shelf) {
      // if ([shelfStatuses.loaned, shelfStatuses.borrowed].includes(shelf.status) && !shelf.borrow) {
      //   history.push("/myShelf")
      //   toast.error("Errore nella visualizzazione di questo libro")
      // }

      setIsHost(shelf.status !== shelfStatuses.borrowed ? b2bUser.id === shelf.user?.id : b2bUser?.id === shelf.borrow?.shelf?.user?.id)
      setDescriptionActive(shelf.book?.abstract !== null)
    }
  }, [b2bUser, shelf])

  useIonViewDidEnter(() => {
    if (cookies.get('user-has-accepted-cookies')) {
      gaEventTracker('Open Book Details')
    }
  })

  const buttonStyle = {
    fontSize: 13,
    textTransform: 'uppercase',

  }

  // const rowStyle = {
  //   marginBottom: 18
  // }

  const inputStyle = {
    background: '#eaeaea',
    margin: '5px 0px 20px',
  }

  const labelStyle = {
    paddingLeft: 13,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  }

  const titleStyle = {
    fontWeight: 400,
    padding: isAndroid || isWeb ? '10px 3px 12px' : '3px 60px',
  }

  return (
    <SecondaryLayout
      showTabs
      toolbar={
        <SecondaryToolbar className="secondary-toolbar">
          <IonButtons slot="start">
            <IonBackButton color="dark" text="" />
          </IonButtons>
          <IonTitle style={titleStyle}>
            { shelf.book?.title }
          </IonTitle>
        </SecondaryToolbar>
      }
    >
      { !loading ?
        <IonGrid>
          <IonRow>
            <IonCol size="5">
              <IonRow class="ion-justify-content-center">
                <img height="180px" src={shelf.book?.thumbnail ? shelf.book?.thumbnail : '/images/missing-book.png'} />
              </IonRow>
              <IonRow class="ion-justify-content-center">
                <IonCardSubtitle
                  style={{ textTransform: 'none', fontWeight: 'lighter !important' }}
                >
                  { shelf?.book?.author }
                </IonCardSubtitle>
              </IonRow>
              { /* <IonRow class="ion-padding">
                <Score value={shelf.book?.avg_score} />
              </IonRow> */ }
            </IonCol>
            { isHost ?
              <IonCol size="7" style={{ paddingTop: 30 }}>
                <IonRow class="ion-justify-content-center">
                  <IonChip >
                    <IonLabel>
                      { [shelfStatuses.private, shelfStatuses.loaned].includes(shelf.status) && 'Non ' }Disponibile
                    </IonLabel>
                  </IonChip>
                </IonRow>
                { shelf.status === shelfStatuses.loaned && (
                  <IonRow class="ion-justify-content-center">
                    { shelf.borrow_id ? <IonText style={{ textAlign: 'center' }}>
                      { `Prestato a ${shelf.borrow?.user?.alias} fino al ${formatDate(shelf.borrow?.to)}` }
                    </IonText> : null }
                  </IonRow>
                ) }
                <IonRow>
                  <IonCol style={{ textAlign: 'center' }}>
                    <IonIcon color={shelf.status === shelfStatuses.loaned ? 'grey' : 'primary'}
                      icon={[shelfStatuses.public, shelfStatuses.loaned].includes(shelf.status) ? lockOpen : lockClosed}
                      onClick={changeStatus}
                      size="large"
                    />
                  </IonCol>
                  <IonCol style={{ textAlign: 'center' }}>
                    <IonIcon
                      color={shelf.status === shelfStatuses.loaned ? 'grey' : 'primary'}
                      icon={trashOutline}
                      onClick={() => {
                        shelf.status !== shelfStatuses.loaned && present({
                          header: 'Elimina',
                          message: 'Sicuro di voler eliminare questo libro dalla tua libreria?',
                          buttons: [
                            'Indietro',
                            { text: 'Ok', handler: () => deleteShelf() },
                          ],
                        })
                      }}
                      size="large"
                    />
                  </IonCol>
                </IonRow>
                { shelf.borrow ? 
                  <IonRow class="ion-justify-content-center">
                    <IonChip onClick={() => history.push(`/chats/${shelf.borrow.id}`)}>
                      Vai alla Chat
                      <IonIcon icon={chevronForwardOutline} />
                    </IonChip>
                  </IonRow> : null }
              </IonCol>
              :
              <IonCol size="7">
                <IonRow>
                  <IonLabel>
                    { shelf.status !== shelfStatuses.public && 'Non ' }Disponibile
                  </IonLabel>
                </IonRow>
                { /* <IonRow style={rowStyle}>
                  <IonCardSubtitle style={{ margin: 0 }}>a 0,3 Km da te</IonCardSubtitle>
                </IonRow> */ }
                <IonRow>
                  Host:
                </IonRow>
                <IonRow >
                  <IonChip onClick={redirectToHostDetails}>
                    <IonLabel style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      { shelf.status === shelfStatuses.borrowed ? shelf.borrow?.shelf?.user?.alias : shelf.user?.alias }
                    </IonLabel>
                    <IonIcon icon={chevronForwardOutline} />
                  </IonChip>

                </IonRow>
                { /* <IonRow style={rowStyle}>
                  <Score onChange={(value) => { changeScore(value) }} value={shelf?.book?.score} />
                </IonRow> */ }
                <IonRow>
                  <IonIcon
                    color={shelf.book?.starred ? 'primary' : 'grey'}
                    icon={heartCircle}
                    onClick={changeStarred}
                    style={{ fontSize: 34 }}
                  />
                  <IonButton 
                    color={[shelfStatuses.loaned, shelfStatuses.borrowed].includes(shelf.status) ? 'light-grey' : 'primary'} 
                    onClick={() => {
                      if (shelf.status === shelfStatuses.public) {
                        present({
                          header: 'Prenota',
                          message: 'Confermi la richiesta di prenotazione?',
                          buttons: [
                            'Indietro',
                            { text: 'Ok', handler: () => book() },
                          ],
                        })
                      } else if (shelf.status === shelfStatuses.borrowed) {
                        history.push(`/chats/${shelf.borrow_id}`)
                      }
                    }} shape="round" size="small" style={buttonStyle}>
                    { [shelfStatuses.loaned, shelfStatuses.borrowed].includes(shelf.status) ? 'In Prestito' : 'Prenota' }
                  </IonButton>
                </IonRow>
              </IonCol> }
          </IonRow>
          { shelf.book?.abstract ? 
            <IonSegment value={descriptionActive ? 'description' : 'details'}>
              <IonSegmentButton onClick={() => {
                setDescriptionActive(true)
              }} value="description">
                Descrizione
              </IonSegmentButton>
              <IonSegmentButton 
                onClick={() => {
                  setDescriptionActive(false)
                }}
                value="details"
              >
                Dettagli
              </IonSegmentButton>
            </IonSegment> : null }
          { descriptionActive ? (
            <div style={{ margin: '12px 0px' }}>
              <IonText>
                { shelf.book?.abstract }
              </IonText>
            </div>

          ) : (
            <div style={{ margin: '12px 0px' }}>
              { shelf.book?.document?.volumeInfo?.categories && shelf.book?.document?.volumeInfo?.categories?.length !== 0 ? <>
                <IonLabel style={labelStyle}>Categorie</IonLabel>
                <IonInput disabled style={inputStyle} type="text" value={shelf?.book?.document?.volumeInfo?.categories?.join(', ')} />
              </> : null }
              { shelf.book?.document?.volumeInfo?.publishedDate ? <>
                <IonLabel style={labelStyle}>Anno di Pubblicazione</IonLabel>
                <IonInput disabled style={inputStyle} type="text" value={formatDate(shelf.book?.document?.volumeInfo?.publishedDate, 'yyyy')} />
              </> : null }
              { shelf.book?.document?.volumeInfo?.publisher ? <>
                <IonLabel style={labelStyle}>Casa Editrice</IonLabel>
                <IonInput disabled style={inputStyle} type="text" value={shelf.book?.document?.volumeInfo?.publisher} />
              </> : null }
            </div>
          ) }
        </IonGrid>
        :
        <IonGrid class="ion-padding">
          <IonRow>
            <IonCol size="5">
              <IonRow class="ion-justify-content-center">
                <IonSkeletonText animated style={{ height: 180, width: 130 }} />
              </IonRow>
              <IonRow class="ion-justify-content-center">
                <IonSkeletonText animated style={{ width: 130 }} />
              </IonRow>
              { /* <IonRow class="ion-padding">
                <Score value={shelf.book?.avg_score} />
              </IonRow> */ }
            </IonCol>
            <IonCol size="7">
              <IonRow>
                <IonSkeletonText animated style={{ width: '60%', height: 16 }} />
              </IonRow>
              <IonRow >
                <IonSkeletonText animated style={{ width: '60%', height: 32, borderRadius: 16 }} />
              </IonRow>
              <IonRow>
                <IonSkeletonText animated style={{ width: '40%', height: 32, borderRadius: 16 }} />
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow class="ion-margin-top">
            <IonSkeletonText animated style={{ height: 16, padding: '0px 10px' }} />
            <IonSkeletonText animated style={{ height: 16, padding: '0px 10px' }} />
            <IonSkeletonText animated style={{ height: 16, padding: '0px 10px' }} />
          </IonRow>
        </IonGrid> }
    </SecondaryLayout>
  )
}

export default BookDetails