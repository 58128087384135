import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react'
import { useTabs } from '../components/Main'
// import { useDevice } from '../providers/DeviceProvider'
import { PropsWithChildren } from 'react'

const EmptyLayout: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<any>) => {
  const { setShowTabs } = useTabs()
  // const { isAndroid, statusBarHeight } = useDevice()

  useIonViewWillEnter(async () => {
    setShowTabs(false)

    try {
      if (Capacitor.isPluginAvailable('StatusBar')) {
        await StatusBar.setBackgroundColor({ color: '#FFFFFF' }) 
        await StatusBar.setStyle({ style: Style.Light })  
      }
    } catch (error) { 
      console.error(error)
    }
  })

  const contentStyle = {
    paddingTop: 0,
    //  paddingTop: isAndroid ? statusBarHeight : '',
    '--padding-bottom': '50px',
  }

  return (
    <IonPage className="safe-area-header">
      <IonContent className='ion-text-center' style={contentStyle}>
        { children }
      </IonContent>
    </IonPage>
  )
}

export default EmptyLayout