import { StatusBar } from '@capacitor/status-bar'
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonImg,
  IonBadge,
  useIonViewDidEnter,
} from '@ionic/react'
import { mdiMapMarkerRadiusOutline } from '@mdi/js'
import Icon from '@mdi/react'
import {
  bookOutline,
  mailOutline,
  personCircleOutline,
} from 'ionicons/icons'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router'
import ProtectedRoute from './ProtectedRoute'
import Auth from '../pages/Auth'
import BookDetails from '../pages/BookDetails'
import BookPointDetails from '../pages/BookPointDetails'
import BookPoints from '../pages/BookPoints'
import Chat from '../pages/chat/Chat'
import ChatList from '../pages/chat/ChatList'
import FirstLogin from '../pages/FirstLogin'
import Home from '../pages/Home'
import Login from '../pages/Login'
import MyShelf from '../pages/MyShelf'
//import NewBookForm from './pages/newBook/NewBookForm';
import Review from '../pages/newBook/Review'
import SearchFromList from '../pages/newBook/SearchFromList'
import SearchIsbn from '../pages/newBook/SearchIsbn'
import PersonalProfile from '../pages/PersonalProfile'
import Settings from '../pages/Settings'
import Tutorial from '../pages/Tutorial'
import UserProfile from '../pages/UserProfile'
import { useAuth } from '../providers/AuthProvider'
import { useMessaging } from '../providers/FirebaseMessagingProvider'
import { App } from '@capacitor/app'
import Error from '../pages/Error'
import { Capacitor } from '@capacitor/core'
import { useDevice } from '../providers/DeviceProvider'
import QrcodeScanner from '../pages/QrcodeScanner'
import ReactErrorBoundary from './ErrorBoundary'
import Communications from '../pages/Communications'
import * as icons  from 'ionicons/icons'
import { addIcons } from 'ionicons'
import { camelCaseToKebab } from 'camelcase-to-kebab'

type TabsProviderOptions = {
  showTabs: Boolean | null | undefined,
  setShowTabs: Function,
  homeClicked?: Boolean,
  setHomeClicked: Dispatch<SetStateAction<Boolean>>,
};

const TabsContext = createContext<TabsProviderOptions | null>(null)

export const useTabs = () => useContext(TabsContext) as TabsProviderOptions

const Main: React.FC = () => {
  const routerRef = useRef<HTMLIonRouterOutletElement | null>(null)

  const { user, token } = useAuth()
  const { messagesCount, loadMessagesStatus } = useMessaging()
  const location = useLocation()
  const history = useHistory()
  const { isAndroid, isWeb } = useDevice()

  const [showTabs, setShowTabs] = useState(false)
  const [pathname, setPathname] = useState<any>(null)
  const [homeClicked, setHomeClicked] = useState<Boolean>(false)

  addIcons(
    Object.fromEntries(Object.entries(icons).map(([key, value]) => [camelCaseToKebab(key), value])),
  )

  useEffect(() => {
    // dato che non abbiamo utilizzato i layout questo componente viene montato in diverse pagine
    // questo vuol dire che il cambio di route scatena N useEffect a seconda di quanti componenti AppTabs sono stati montati
    // per evitare che navigando si accodino sempre più chiamate di message_status ogni componente AppTabs chiamerà message_status solo sulla route di sua competenza
    // es: se questo componente viene montato nella pagina bookPoints, attiverà la chiamata message_status ogni volta che history.location.pathname sarà uguale a /bookPoints
    if (user && token && (!pathname || pathname !== location.pathname) && isWeb) {
      loadMessagesStatus()

      setPathname(location.pathname)
    } else if (user && !isWeb)
      loadMessagesStatus()
  }, [user, token, location])

  useEffect(() => {
    if (Capacitor.isPluginAvailable('StatusBar') && isAndroid) {
      // StatusBar.setOverlaysWebView({ overlay: false })
      StatusBar.setBackgroundColor({ color: '#ed7459' })
    }
  }, [isAndroid])

  useIonViewDidEnter(() => {
    App.addListener('backButton', ({ canGoBack }) => {
      if (canGoBack) {
        history.goBack()
      } else {
        App.exitApp()
      }
    })
  })

  const tabBarPadding = () => {
    if (!showTabs) return '0px'
    // if(isAndroid) {
    //   return `min(${safeArea?.insets.bottom ?? 0}px, var(--ion-safe-area-top, 0))`
    // }
    return 'env(safe-area-inset-bottom)'
  }

  const routerOutletStyle = {
    height: 'calc(100% + 50px)',
    marginBottom: showTabs ? 50 : '',
  }

  const tabBarStyle = {
    height: showTabs ? '50px' : '0px',
    paddingBottom: tabBarPadding(),
    // paddingBottom: 'var(--ion-safe-area-bottom, 0)',
    transition: '0.2s',
  }

  return (
    <TabsContext.Provider value={{ showTabs, setShowTabs, homeClicked, setHomeClicked }}>
      <ReactErrorBoundary>
        <IonTabs>
          <IonRouterOutlet style={routerOutletStyle}>
            <Redirect exact from='/' to={token ? '/home' : '/login'} />
            <Route component={Login} path="/login" />
            <Route component={Home} path="/home" />

            <Route component={Auth} path="/auth" />
            <Route component={Error} path="/error" />
            <ProtectedRoute component={FirstLogin} path="/firstLogin" />

            <Route component={BookDetails} path="/hostShelf/:id" />
            <ProtectedRoute component={SearchIsbn} path="/new_book/search_isbn" />
            <ProtectedRoute component={SearchFromList} path="/new_book/search_from_list" />
            { /* <ProtectedRoute path="/new_book/new_book_form" component={NewBookForm} /> */ }
            <ProtectedRoute component={Review} path="/new_book/review" />

            <ProtectedRoute component={MyShelf} exact path="/myShelf" />
            <Route component={BookDetails} exact path="/myShelf/:id" />
            <Route component={BookPoints} exact path="/bookPoints" />
            <Route component={BookPointDetails} path="/bookPoints/:id" />

            <ProtectedRoute component={ChatList} exact path="/chats" />
            <ProtectedRoute component={QrcodeScanner} path="/scan_qr" />
            <ProtectedRoute path="/chats/:id" render={() => <Chat router={routerRef.current} />} />
            <ProtectedRoute exact path="/profile" render={() => <PersonalProfile router={routerRef.current} />} />
            <Route component={UserProfile} path="/user/:id?" />
            <ProtectedRoute component={Settings} exact path="/profile/settings" />
            <Route component={Tutorial} path="/profile/settings/tutorial" />

            <ProtectedRoute component={Communications} exact path="/profile/communications" />
          </IonRouterOutlet>

          <IonTabBar slot="bottom" style={tabBarStyle}>
            <IonTabButton href="/myShelf" tab="myShelf">
              <IonIcon icon={bookOutline} />
            </IonTabButton>
            <IonTabButton href="/bookPoints" tab="bookPoints">
              <Icon path={mdiMapMarkerRadiusOutline} size={1.4} />
            </IonTabButton>
            <IonTabButton href="/home" onClick={() => setHomeClicked(true)} tab="home">
              <IonImg src="/images/monogramma.png" style={{ height: '80%' }} />
            </IonTabButton>
            <IonTabButton href="/chats" tab="chat">
              <IonIcon icon={mailOutline} />
              {
                messagesCount > 0 &&
                  <IonBadge color="primary">{ messagesCount }</IonBadge>
              }
            </IonTabButton>
            <IonTabButton href="/profile" tab="profile">
              <IonIcon icon={personCircleOutline} />
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </ReactErrorBoundary>
    </TabsContext.Provider >
  )
}

export default Main