
import {
  IonContent,
  IonHeader,
  IonPage,
  ScrollDetail,
  useIonViewWillEnter,
} from '@ionic/react'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { PropsWithChildren, ReactNode } from 'react'
import { useTabs } from '../components/Main'
// import { useDevice } from '../providers/DeviceProvider'

interface HomeLayoutProps {
  toolbar: ReactNode,
  loading?: boolean,
  search: string,
  onIonScroll: (event: CustomEvent<ScrollDetail>) => void,
}

const HomeLayout: React.FC<PropsWithChildren<HomeLayoutProps>> = ({ children, toolbar, onIonScroll }: PropsWithChildren<HomeLayoutProps>) => {
  const { setShowTabs } = useTabs()
  // const { statusBarHeight, isAndroid } = useDevice()

  useIonViewWillEnter(async () => {
    setShowTabs(true)
    try {
      if(Capacitor.isPluginAvailable('StatusBar')) {
        await StatusBar.setStyle({ style: Style.Dark })
        await StatusBar.setBackgroundColor({ color: '#ed7459' })
      }
    } catch (error) { console.error(error) }
  })

  const homeHeaderStyle = {
    minHeight: 60,
    background: 'linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 100%)',
    borderRadius: '0px 0px 20px 20px',
  }

  return (
    <IonPage>
      <IonHeader
        class="ion-no-border"
        style={homeHeaderStyle}
      >
        { toolbar }
      </IonHeader>

      <IonContent
        onIonScroll={onIonScroll}
        scrollEvents
        style={{ position: 'relative', top: '-20px' }}
      >
        { children }
      </IonContent>
    </IonPage>
  )
}

export default HomeLayout