import { IonText } from '@ionic/react'
import { DotProps } from '~types/components'

const Dot: React.FC<DotProps> = ({ open }: DotProps) => {
  const redDot = {
    height: 12,
    width: 12,
    backgroundColor: '#bb0202',
    borderRadius: '50%',
    display: 'inline-block',
  }

  const greenDot = {
    height: 12,
    width: 12,
    backgroundColor: '#028702',
    borderRadius: '50%',
    display: 'inline-block',
  }

  return (
    <IonText float-right>
      { open ?
        <><span style={greenDot} /> Aperto</>
        :
        <><span style={redDot} /> Chiuso</> }
    </IonText>
  )
}

export default Dot