import {
  IonContent,
  IonButton,
  IonIcon,
  IonRouterLink,
  IonImg,
  IonCard,
  IonModal,
  IonCardTitle,
  useIonViewDidEnter,
  IonInput,
  IonText,
  IonCheckbox,
  IonLabel,
  useIonViewWillLeave,
} from '@ionic/react'
import {
  signInWithGoogle,
  signInWithApple,
  signInWithEmail,
} from '../plugins/firebase'
import { logoGoogle, logoApple } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useToast } from '../providers/ToastProvider'
import useInput from '../hooks/useInput'
import { useAuth } from '../providers/AuthProvider'
import { useDevice } from '../providers/DeviceProvider'
// import CookieBanner from 'react-cookie-banner';
// import ReactGA from 'react-ga4';
import EmptyLayout from '../layout/EmptyLayout'
import { Browser } from '@capacitor/browser'

const Login: React.FC = () => {
  const { b2bUser, present, dismiss } = useAuth()
  const { isWeb, isAndroid } = useDevice()
  const [showModal, setShowModal] = useState(false)
  const [showEmailAndPasswordModal, setShowEmailAndPasswordModal] = useState(false)
  const [checkboxPrivacy, setCheckboxPrivacy] = useState(false)
  const [displayName, setDisplayName] = useState<any>(null)
  const email = useInput('')
  const password = useInput('')

  const history: any = useHistory()
  const toast = useToast()

  useEffect(() => {
    if (b2bUser && displayName) {
      setShowModal(false)
      history.replace(b2bUser.need_setup ? {
        pathname: '/firstLogin',
        search: `?displayName=${displayName}`,
      } : '/home')
    } else if (b2bUser && !displayName) {
      setShowModal(false)
      history.replace(b2bUser.need_setup ? '/firstLogin' : '/home')
    }
  }, [b2bUser, displayName])

  useEffect(() => {
    if (checkboxPrivacy) {
      localStorage.setItem('terms_and_privacy', 'true')
    }
  }, [checkboxPrivacy])

  useIonViewDidEnter(() => {
    if (history.location.state && history.location.state.loginModalOpen) {
      setShowModal(true)
      let state = { ...history.location.state }
      delete state.loginModalOpen
      history.replace({ ...history.location, state })
    } else {
      setShowModal(false)
    }
    if (localStorage.getItem('terms_and_privacy')) {
      setCheckboxPrivacy(true)
    }
  })

  useIonViewWillLeave(() => {
    setShowModal(false)
  })

  const signInGoogle = async () => {
    try {
      present('Caricamento')
      setShowModal(false)
      await signInWithGoogle()
    } catch (error: any) {
      console.error(error)
      dismiss()
      toast.error('Errore durante l\'autenticazione con Google')
    }
  }

  const signInApple = async () => {
    try {
      present('Caricamento')
      setShowModal(false)
      const user = await signInWithApple()
      setDisplayName(user?.displayName)
    } catch (error) {
      console.error(error)
      dismiss()
      toast.error('Errore durante l\'autenticazione con Apple')
    }
  }

  const signInWithEmailAndPassword = async () => {
    try {
      await signInWithEmail(email.value, password.value)
      setShowEmailAndPasswordModal(false)
    } catch (error: any) {
      console.error(error)
      let message: string = 'Errore durante l\'autenticazione con email e password: '
      switch (error.code) {
        case 'auth/invalid-email':
          message += '<strong>Email non valida</strong>'
          break
        case 'auth/wrong-password':
          message += '<strong>Password errata</strong>'
          break
        case 'auth/user-not-found':
          message += '<strong>Utente non trovato</strong>'
          break
        case 'auth/network-request-failed':
          message += '<strong>Errore di connessione</strong>'
          break
      }
      toast.error(message)
    }
  }

  const openPrivacyAndPolicyLink = async () => {
    await Browser.open({ url: 'https://book2book.org/privacy-policy' })
  }

  const imgStyle = {
    width: '50%',
    margin: '16px auto auto auto',
  }

  const titleStyle = {
    margin: 20,
  }

  const cardStyle = {
    background: 'linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 100%)',
    color: '#FFFFFF',
    paddingBottom: 10,
    height: 350,
    marginLeft: 26,
    marginRight: 26,
  }
  const buttonStyle = {
    marginTop: 10,
    marginLeft: 50,
    marginRight: 50,
    marginBottom: 10,
    fontSize: 18,
  }

  const enterNoLogin = {
    color: 'black',
    fontSize: 18,
    textDecoration: 'underline',
    marginTop: 20,
  }

  const modalButtonStyle = {
    color: 'var(--ion-text-color)',
    textTransform: 'none',
  }

  const inputStyle = {
    background: '#eaeaea',
    marginTop: 10,
  }

  return (
    <EmptyLayout>
      <IonImg src="/images/logo_hori_600x200.png" style={imgStyle} />
      <h5 style={titleStyle}><strong>Leggi, presta, incontra.</strong></h5>

      <Swiper pagination>
        <SwiperSlide>
          <IonCard class="ion-text-center" style={cardStyle}>
            <IonImg src='/images/personaggi-onboarding.svg' style={{ maxWidth: '350px', height: '88%', margin: '0px auto', marginBottom: '-150px' }} />
            <IonCardTitle class="ion-padding" style={{ 'color': 'white', fontSize: '17px', fontWeight: '600' }}>
              Benvenut* in Book2Book!
            </IonCardTitle>
            <div style={{ fontSize: '16px', padding: '0px 16px' }}>
              Unisciti alla community per scambiare libri e conoscere nuove persone vicino a te!
            </div>
          </IonCard>
        </SwiperSlide>

        <SwiperSlide>
          <IonCard class="ion-text-center" style={cardStyle}>
            <div style={{ fontSize: '16px', 'padding': '64px 16px 16px 16px' }}>
              Book2Book utilizza i libri come mezzo di incontro per creare una rete tra persone e luoghi di aggregazione della città.
            </div>
            <IonCardTitle style={{ 'color': 'white', fontSize: '17px', fontWeight: '600', padding: '16px' }}>
              Scopri come facendo il login!
            </IonCardTitle>
          </IonCard>
        </SwiperSlide>

        <SwiperSlide>
          <IonCard class="ion-text-center" style={cardStyle}>
            <IonCardTitle style={{ 'color': 'white', fontSize: '17px', fontWeight: '600', padding: '32px 16px 0px 16px' }}>
              Vuoi prestare un libro?
            </IonCardTitle>
            <div style={{ fontSize: '16px', 'padding': '16px' }}>
              Loggati, scannerizza il codice a barre e aspetta che ti scrivano!
            </div>
            <IonCardTitle style={{ 'color': 'white', fontSize: '17px', fontWeight: '600', padding: '16px 16px 0px 16px' }}>
              Vuoi prendere un libro?
            </IonCardTitle>
            <div style={{ fontSize: '16px', 'padding': '16px' }}>
              Cerca un titolo o lasciati ispirare per la tua prossima lettura. Quando hai scelto scrivi all&apos;utente e concordate l&apos;incontro!
            </div>
          </IonCard>
        </SwiperSlide>
      </Swiper>

      <IonButton
        expand="block"
        onClick={() => setShowModal(true)}
        shape="round"
        size="small"
        style={buttonStyle}
      >
        Accedi
      </IonButton>
      { /* 
          <IonButton
            style={buttonStyle}
            size="small"
            fill="outline"
            expand="block"
            shape="round"
          >
            Iscriviti
          </IonButton> 
        */ }
      <IonRouterLink href="/home" style={enterNoLogin}>Entra senza login</IonRouterLink>

      <IonModal className="login-modal" isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <div className="ion-padding ion-text-center wrapper">
          <h5 style={titleStyle}><strong>Accedi</strong></h5>
          <IonButton
            color="var(--ion-text-color)"
            disabled={!checkboxPrivacy}
            expand='block'
            fill="outline"
            onClick={async () => await signInGoogle()}
            shape="round"
            size="large"
            strong
            style={modalButtonStyle}
          >
            <IonIcon
              color="primary"
              icon={logoGoogle}
              slot="start"
            />
            Accedi con Google
          </IonButton>
          
          { !isWeb && !isAndroid && (
            <IonButton
              color="var(--ion-text-color)"
              disabled={!checkboxPrivacy}
              expand='block'
              fill="outline"
              onClick={async () => await signInApple()}
              shape="round"
              size="large"
              strong
              style={modalButtonStyle}
            >
              <IonIcon
                color="primary"
                icon={logoApple}
                slot="start"
              />
              Accedi con Apple
            </IonButton>
          ) }
          <IonText color="primary" onClick={() => {
            setShowModal(false)
            setShowEmailAndPasswordModal(true)
          }}>Accedi come BookPoint</IonText>
          <br />
          <IonLabel style={{ fontSize: 12 }}>
            <IonCheckbox checked={checkboxPrivacy} onIonChange={e => setCheckboxPrivacy(e.detail.checked)} style={{ verticalAlign: 'middle', marginRight: 5 }} />
            Dichiaro di aver letto e compreso i <a onClick={openPrivacyAndPolicyLink}>termini e condizioni</a> del servizio e di aver preso visione dell&apos;informativa privacy.
          </IonLabel>
        </div>
      </IonModal>

      <IonModal className="login-email-modal" isOpen={showEmailAndPasswordModal}
        onDidDismiss={() => setShowEmailAndPasswordModal(false)}>
        <IonContent className="ion-padding ion-text-center" scrollY={false}>
          <IonInput placeholder="Email" style={inputStyle} type="email" {...email} />
          <IonInput placeholder="Password" style={inputStyle} type="password" {...password} />
          <IonButton
            disabled={(email.value === '' && password.value === '') || !checkboxPrivacy}
            expand="block"
            onClick={signInWithEmailAndPassword}
            shape="round"
            size="small"
            style={buttonStyle}>Accedi</IonButton>
          <IonCheckbox 
            checked={checkboxPrivacy} 
            onIonChange={e => setCheckboxPrivacy(e.detail.checked)}
            style={{ verticalAlign: 'middle', marginRight: 5 }} 
          />
          <IonLabel style={{ fontSize: 12 }}>
            Dichiaro di aver letto e compreso i termini e condizioni del servizio e di aver preso visione dell&apos;informativa privacy.
          </IonLabel>
        </IonContent>
      </IonModal>
    </EmptyLayout>
  )
}

export default Login
