import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonImg,
  IonRow,
  IonText,
} from '@ionic/react'
import { informationCircleOutline } from 'ionicons/icons'
import { messageStatuses } from '../enums/MessageStatuses'
import { MessageProps } from '~types/components'

const ChatRow: React.FC<MessageProps> = ({ message }: MessageProps) => {
  const cardStyleSender = {
    borderRadius: '10px 0px 10px 10px',
    margin: 0,
  }
  
  const cardStyleReceiver = {
    borderRadius: '0px 10px 10px 10px',
    margin: 0,
  }

  const sentTimeStyle = {
    fontSize: 10,
    float: message.isSender ? 'right' : 'left',
    marginLeft: message.isSender ? 'auto' : 8,
    marginRight: message.isSender ? 8 : 'auto',
  }

  const infoCardStyle = {
    color: 'info',
    margin: '0px 30px',
  }

  return (
    <IonRow>
      { !message.isSender && message.type !== messageStatuses.system_message && (
        <IonAvatar style={{ maxHeight: 40, maxWidth: 40, marginRight: 2 }}>
          <IonImg src={message.receiverAvatar ? message.receiverAvatar : '/images/no-avatar.png'} />
        </IonAvatar>
      ) }
      {
        message.type === messageStatuses.system_message ?
          <IonCol style={{ padding: '5px 0px' }}>
            <IonCard color="info" style={infoCardStyle}>
              <IonCardContent 
                style={{ padding: '5px 0px', fontSize: 14, textAlign: 'center' }} 
              >
                <IonIcon 
                  icon={informationCircleOutline} 
                  style={{ verticalAlign: 'middle', fontSize: 20, paddingBottom: 2, paddingRight: 7 }} 
                />
                { message.message }
              </IonCardContent>
            </IonCard>
          </IonCol>
          :
          <IonCol 
            offset={message.isSender ? '4' : '0'} 
            size="8" 
            style={{ padding: '5px 0px' }}>
            <IonRow>
              <IonText style={sentTimeStyle}>
                { message.sentTime }
              </IonText>
            </IonRow>
            <IonCard 
              color={message.isSender ? 'primary' : 'light-grey'} 
              style={message.isSender ? cardStyleSender : cardStyleReceiver}
            >
              <IonCardContent style={{ padding: '10px 13px', fontSize: 14 }} >
                { message.message }
              </IonCardContent>
            </IonCard>
          </IonCol>
      }
    </IonRow >
  )
}

export default ChatRow