import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonText,
  RefresherEventDetail,
} from '@ionic/react'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import BookPointCard from '../components/BookPointCard'
import { useToast } from '../providers/ToastProvider'
import { Geolocation, Position } from '@capacitor/geolocation'
import { bookpointsWithDistance } from '../plugins/distance'
import DefaultLayout from '../layout/DefaultLayout'
import BookPointToolbar from '../components/BookPointToolbar'
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import L, { divIcon } from 'leaflet'
import Dot from '../components/Dot'
import { renderToStaticMarkup } from 'react-dom/server'
import type { BookPoint, BookPointFilterParams } from '~types/bookpoint'
import { Device } from '@capacitor/device'

const BookPoints: React.FC = () => {
  const defaultFilter: BookPointFilterParams = {
    opened: 0,
    has_shelfs: 0,
  }

  const [loading, setLoading] = useState(false)
  const [bookPoints, setBookPoints] = useState<BookPoint[]>([])
  const [filter, setFilter] = useState(defaultFilter)
  const [selectedSegment, setSelectedSegment] = useState<string | undefined>('list')
  const [position, setPosition] = useState<Position | null | undefined>(undefined)
  const [mapCenter, setMapCenter] = useState<any>(null)
  const [mapZoom, setMapZoom] = useState<number>(12)
  const [bounds, setBounds] = useState<any>()
  const filteredBookPoints = useMemo(() => {
    return bookpointsWithDistance(bookPoints, position)
      .filter(bookPoint =>
        (filter?.has_shelfs ? bookPoint.has_shelfs : true) && (filter?.opened ? bookPoint.open : true),
      )
  }, [bookPoints, filter, position])
  const [search, setSearch] = useState('')
  const history = useHistory()
  const toast = useToast()

  const markerIcon = new L.Icon({
    iconUrl: require('../img/marker-icon.png'),
    iconRetinaUrl: require('../img/marker-icon.png'),
    iconSize: new L.Point(30, 30, true),
    className: 'leaflet-div-icon',
  })

  const userIcon = new L.Icon({
    iconUrl: require('../img/user-position-icon.png'),
    iconRetinaUrl: require('../img/user-position-icon.png'),
    iconSize: new L.Point(30, 30, true),
    className: 'leaflet-div-icon',
  })

  const markerOtherIcon = (bookpoint: BookPoint) => {
    const bookPointIconStyle = {
      color: bookpoint.color, 
      fontSize: 30,
      textAlign: 'center',
      flexGrow: 1,
      padding: 3,
    }
    const avatarStyle = {
      width: 35,
      height: 35,
      backdropFilter: 'brightness(70%)',
    }

    return divIcon({
      html: renderToStaticMarkup(
        <IonAvatar style={avatarStyle}>
          <IonIcon icon={bookpoint.icon} style={bookPointIconStyle} />
        </IonAvatar>,
      ),
    })
  }

  const loadBookPoints = async () => {
    setLoading(true)

    try {
      const { data: bookPoints } = await axios.get('bookpoints', { params: { search, type: 'all' } })

      setBookPoints(bookPoints)
      const group = L.featureGroup(bookPoints.map((b: BookPoint) => L.marker([Number(b.latitude), Number(b.longitude)])))

      setBounds(group.getBounds())
    } catch (error) {
      console.error(error)
      toast.error('Errore nel recupero dei bookpoint')
    }

    setLoading(false)
  }

  const setCurrentPosition = async () => {
    try {
      const deviceInfo = await Device.getInfo()
      const isMobile = deviceInfo.platform && (['ios', 'android'].includes(deviceInfo.platform))

      if (!isMobile) {
        const userCoords = await Geolocation.getCurrentPosition()
        setPosition(userCoords)
        setMapCenter([userCoords.coords.latitude, userCoords.coords.longitude])
      } else {
        const permissions = await Geolocation.requestPermissions()
      
        if(permissions.location === 'granted') {
          const userCoords = await Geolocation.getCurrentPosition()
          setPosition(userCoords)
          setMapCenter([userCoords.coords.latitude, userCoords.coords.longitude])
        }
      }
    } catch (error: any) {
      // 1 -> permission denied => l'utente non ha accettato di condividere la posizione
      if (error.code !== 1) {
        console.error(error)
        // toast.error('Errore nel recupero della posizione')
      }

      setPosition(null)
    }
  }

  const distance = (d: number) => {
    let ret = 'a '
    ret += d >= 1000 ?
      (Number((d / 1000).toFixed(1))) + ' km'
      :
      d + ' m'
    ret += ' da te'
    return ret
  }

  const availableShelfs = (stats: any) => {
    let ret = ''
    if (stats.shelfs === 0 && stats.borrows === 0) return 'Nessun Libro disponibile'
    ret = `${stats.shelfs} ${stats.shelfs === 1 ? 'Libro disponibile' : 'Libri disponibili'}`
    if (stats.borrows !== 0)
      ret += `, ${stats.borrows} ${stats.borrows === 1 ? 'Scambio avvenuto' : 'Scambi avvenuti'}`
    return ret
  }

  const refreshBookPoints = async (event: CustomEvent<RefresherEventDetail>) => {
    await loadBookPoints()
    event.detail.complete()
  }

  const MapInnerComponent = () => {
    const map = useMap()
    useMapEvents({
      moveend: () => {
        const center = map.getCenter()
        setMapCenter([center.lat, center.lng])
        setMapZoom(map.getZoom())
      },
    })
    return null
  }

  useEffect(() => {
    loadBookPoints()
  }, [search])

  useEffect(() => {
    if (!position)
      setCurrentPosition()
  }, [position])

  const layoutContentStyle = {
    '--padding-bottom': selectedSegment === 'list' ? '50px': '0px',
  }

  return (
    <DefaultLayout
      contentStyle={layoutContentStyle}
      loading={loading}
      toolbar={
        <BookPointToolbar
          {...{ 
            search, 
            setSearch, 
            filter,
            setFilter,
            selectedSegment,
            setSelectedSegment, 
          }}
        />
      }
    >
      { selectedSegment === 'list' ?
        <>
          <IonRefresher onIonRefresh={refreshBookPoints} slot="fixed">
            <IonRefresherContent />
          </IonRefresher>
          <IonGrid style={{ paddingTop: 10 }}>
            {
              filteredBookPoints.map((bookPoint: BookPoint, i: number) =>
                (<BookPointCard
                  bookPoint={bookPoint}
                  key={i}
                  onClick={() => history.push(`/bookPoints/${bookPoint.id}`)}
                />),
              )
            }
          </IonGrid>
        </>
        :
        <MapContainer
          attributionControl={false}
          bounds={bounds}
          center={mapCenter}
          zoom={mapZoom}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup>
            { filteredBookPoints.map((b: any, i: number) =>
              (<Marker
                icon={b.type === 'other' ? markerOtherIcon(b) : markerIcon}
                key={i}
                position={[Number(b.latitude), Number(b.longitude)]}
              >
                <Popup minWidth={300}>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonText style={{ fontSize: 15 }}>
                          <strong>
                            { b.full_name }
                          </strong>
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      { b.address ? <IonCol>
                        <IonText>
                          { b.address }
                        </IonText>
                      </IonCol> : null }
                    </IonRow>
                    { b.distance ? <IonRow>
                      <IonCol>
                        <IonText>
                          { distance(b.distance) }
                        </IonText>
                      </IonCol>
                    </IonRow> : null }
                    <IonRow>
                      <IonCol size="9">
                        <IonText>
                          { b.current_timetable ?
                            `${b.current_timetable?.label} ${b.current_timetable?.start} - ${b.current_timetable?.end} `
                            :
                            'Oggi Chiuso' }
                        </IonText>
                      </IonCol>
                      <IonCol size="3">
                        <Dot open={b.open} />
                      </IonCol>
                    </IonRow>
                    { b.type !== 'other' && (
                      <IonRow>
                        <IonCol>
                          <IonText>
                            { availableShelfs(b.stats) }
                          </IonText>
                        </IonCol>
                      </IonRow>
                    ) }
                  </IonGrid>
                  <IonButton
                    expand='block'
                    onClick={() => history.push(`/bookPoints/${b.id}`)}
                    size="small"
                  >
                    Altre info
                  </IonButton>
                </Popup>
              </Marker>),
            ) }
          </MarkerClusterGroup>
          { position ? 
            <Marker
              icon={userIcon}
              position={[position.coords.latitude, position.coords.longitude]}
            /> : null }
          <MapInnerComponent />
        </MapContainer> }
    </DefaultLayout>
  )}

export default BookPoints