import { BarcodeScanner } from '@capacitor-community/barcode-scanner'
import { Device } from '@capacitor/device'
import {
  BrowserMultiFormatReader,
  BarcodeFormat,
  DecodeHintType,
} from '@zxing/library'

const codeReader = new BrowserMultiFormatReader()
let source: string | undefined = undefined
const hints = new Map()
const formats = [BarcodeFormat.EAN_13]

async function webReader() {
  hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)

  if(!source) {
    codeReader.listVideoInputDevices().then(videoInputDevices => {
      source = videoInputDevices[0].deviceId
      console.info(videoInputDevices[0])
    })
  }
 
  try {
    // deviceId: undefined take always the main camera (usually back)
    const result: any = await codeReader.decodeOnceFromVideoDevice(undefined, 'video')

    return result?.text
  } catch(error) {
    console.error(error)
  } 
}

async function appReader() {
  // Check camera permission
  // This is just a simple example, check out the better checks below
  const permissions = await BarcodeScanner.checkPermission({ force: true })

  if (!permissions.asked && (permissions.neverAsked || permissions.denied)) {
    if (confirm('Per utilizzare la fotocamera bisogna aver accettato i permessi nelle impostazioni'))
      await BarcodeScanner.openAppSettings()
  }

  // make background of WebView transparent
  BarcodeScanner.hideBackground()
  document.querySelector('body')?.classList.add('scanner-active')

  const result = await BarcodeScanner.startScan()

  if (result.hasContent) {
    return result.content
  }
  document.querySelector('body')?.classList.remove('scanner-active')
}

async function readIsbnBarcode() {
  const { platform } = await Device.getInfo()

  if (platform == 'web')
    return webReader()
  else
    return appReader()
}

async function stopScan() {
  const { platform } = await Device.getInfo()

  if (platform == 'web'){
    try {
      codeReader.reset()
      codeReader.stopAsyncDecode()
      codeReader.stopContinuousDecode()
    } catch(error) {
      console.error(error)
    }   
  } else {
    BarcodeScanner.showBackground()
    BarcodeScanner.stopScan()
    document.querySelector('body')?.classList.remove('scanner-active')
  }
}

export { readIsbnBarcode, stopScan }