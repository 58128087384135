import { Position } from '@capacitor/geolocation'
import type { BookPoint } from '~types/bookpoint'

const distance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371e3 // metres
  const φ1 = lat1 * Math.PI / 180 // φ, λ in radians
  const φ2 = lat2 * Math.PI / 180
  const Δφ = (lat2 - lat1) * Math.PI / 180
  const Δλ = (lon2 - lon1) * Math.PI / 180

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const d = R * c // in metres

  // approssimo il risultato
  return Math.round(d)
}

function bookpointsWithDistance(bookPoints: any, position: Position | null | undefined = null): BookPoint[] {
  if (position) {
    bookPoints.map((bookPoint: BookPoint) => {
      bookPoint.distance = distance(position.coords.latitude, position.coords.longitude, Number(bookPoint.latitude), Number(bookPoint.longitude))

      return bookPoint
    })

    bookPoints.sort((a: BookPoint, b: BookPoint) => a.distance - b.distance)
  } else {
    bookPoints.sort((a: BookPoint, b: BookPoint) => a.full_name < b.full_name)
  }

  return bookPoints
}

function shelfsWithDistance(shelfs: any, position: Position | null | undefined = null) {
  if (position) {
    shelfs.map((shelf: any) => {
      if (shelf.user?.latitude && shelf.user?.longitude) {
        shelf.distance = distance(position.coords.latitude, position.coords.longitude, Number(shelf.user.latitude), Number(shelf.user.longitude))
      }
      return shelf
    })

    shelfs.sort((a: any, b: any) => a.distance < b.distance)
  }

  return shelfs
}

export { distance, bookpointsWithDistance, shelfsWithDistance }