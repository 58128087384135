import { User } from '@capacitor-firebase/authentication'
import { useIonLoading } from '@ionic/react'
import axios from 'axios'
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router'
import useLocalStorageState from 'use-local-storage-state'
import { getIdToken, onAuthStateChanged, removeListeners } from '../plugins/firebase'
import { useToast } from './ToastProvider'
import { SplashScreen } from '@capacitor/splash-screen'
import { App } from '@capacitor/app'
import { useDevice } from './DeviceProvider'
import { B2BUser } from '~types/b2b-user'

type AuthProviderOptions = {
  token: String | null | undefined,
  user: User | null | undefined,
  b2bUser: any | null | undefined,
  setB2BUser: Dispatch<SetStateAction<B2BUser | null>>,
  present: Function,
  dismiss: Function,
  reset: Function,
  userError: any,
  refreshB2bUser: Function,
};



const AuthContext = createContext<AuthProviderOptions | null>(null)

export const useAuth = () => useContext(AuthContext) as AuthProviderOptions

export const AuthProvider: React.FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<any>) => {
  const [user, setUser] = useState<User | null>()
  const [b2bUser, setB2BUser] = useState<B2BUser | null>(null)
  const [userError, setUserError] = useState<any>(null)
  const toast = useToast()
  const { isWeb } = useDevice()
  const [token, setToken, { removeItem }] = useLocalStorageState<String | undefined>('token', {
    defaultValue: '',
  })
  const [present, dismiss] = useIonLoading()
  const history = useHistory()


  useEffect(() => {
    onAuthStateChanged(async (user: any) => {
      const token = await getIdToken()

      setUser(user)
      setToken(token ? `Bearer ${token}` : '')

      if (!user) {
        setUser(null)
        await SplashScreen.hide()
      }
    })

    return function cleanup() {
      removeListeners()
    }
  }, [])

  useEffect(() => {
    if (b2bUser && b2bUser.need_setup)
      history.replace('/firstLogin')
  }, [b2bUser])

  useEffect(() => {
    if (isWeb !== undefined && !isWeb) {
      App.addListener('appStateChange', async ({ isActive }) => {
        if (isActive) {
          const token = await getIdToken()
          setToken(token ? `Bearer ${token}` : '')
        }
      })
    }

    return function cleanup() {
      App.removeAllListeners()
    }
  }, [isWeb])

  useEffect(() => {
    if (user && token)
      loadB2BUser()
  }, [user, token])

  const loadB2BUser = async () => {
    try {
      const { data } = await axios.get('user')
      setB2BUser(data)
      dismiss()
      await SplashScreen.hide()
      setUserError(null)
    } catch (error: any) {
      dismiss()
      await SplashScreen.hide()
      toast.error('Errore nel recupero dell\'utente')
      setUserError(error?.response?.message ?? 'Errore di rete, verifica la tua connessione')
      history.replace('/error')
    }
  }

  const reset = async () => {
    setB2BUser(null)
    removeItem()
  }

  const refreshB2bUser = async () => {
    try {
      const { data } = await axios.get('user')
      setB2BUser(data)
    } catch (error) {
      console.error(error)
      toast.error('Errore nel recupero delle info utente')
    }
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        b2bUser,
        setB2BUser,
        present,
        dismiss,
        reset,
        userError,
        refreshB2bUser,
      }}
    >
      { children }
    </AuthContext.Provider>
  )
}
