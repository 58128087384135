import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonChip,
  IonCol,
  IonLabel,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { mdiMapMarkerRadiusOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { Link } from 'react-router-dom'
import { borrowStatuses } from '../enums/BorrowStatuses'
import SecondaryToolbar from './SecondaryToolbar'

import type { ChatToolbarProps } from '~types/components'

const ChatToolbar: React.FC<ChatToolbarProps> = ({ borrow, bookPoint, receiver, present }: ChatToolbarProps) => {
  const showInfo = () => {
    present({
      header: 'Info',
      message: borrow?.days < 0 ? `Questo prestito è scaduto da ${Math.abs(borrow?.days)} giorni. Affrettati a restituire il libro!` : `Manca${borrow?.days === 1 ? '' : 'no'} ${borrow?.days} giorn${borrow?.days === 1 ? 'o' : 'i'} alla scadenza del prestito`,
      buttons: ['Ok'],
    })
  }

  const infoCardStyle = {
    margin: '7px 5px 0px',
    padding: '1px 10px',
    textAlign: 'center',
  }

  const chipStyle = {
    fontSize: 13,
  }

  return (
    <>
      <SecondaryToolbar className="secondary-toolbar">
        <IonButtons slot="start">
          <IonBackButton color="dark" defaultHref="/chats" text="" />
        </IonButtons>
        <IonTitle class="ion-no-padding">
          <Link style={{ color: 'black', textDecoration: 'none' }} to={`/user/${receiver.id}`}>{ receiver?.alias }</Link>
        </IonTitle>
      </SecondaryToolbar>
      <IonToolbar class="light-toolbar">
        { borrow?.status === borrowStatuses.sync_pending && (
          <IonCard class="ion-text-center" color="light-secondary" style={infoCardStyle}>
            <Icon path={mdiMapMarkerRadiusOutline} size={0.7} style={{ verticalAlign: 'middle' }} />
            { bookPoint !== null && `${bookPoint.full_name} - ` }{ borrow?.sync_address }
          </IonCard>
        ) }
        { borrow?.status === borrowStatuses.sync_pending && borrow?.shelf.user.bookpoint && bookPoint?.borrows_timetables ? <IonRow>
          <IonCol class="ion-no-padding">
            <IonCard color="info" style={infoCardStyle}>
              Orari per il prestito:
              <IonText>
                {
                  bookPoint?.borrows_timetables?.map((t: any) => {
                    return `${t.days} ${t.hours}`
                  })
                }
              </IonText>
            </IonCard>
          </IonCol>
        </IonRow> : null }
        <IonRow class="ion-text-center ion-align-items-center">
          <IonCol size={[borrowStatuses.active, borrowStatuses.extension_pending].includes(borrow?.status) ? '9' : '12'}>
            <Link style={{ color: 'black', fontSize: 18 }} to={`/hostShelf/${borrow?.shelf?.id}`}>{ borrow?.shelf?.book.title }</Link>
          </IonCol>
          { [borrowStatuses.active, borrowStatuses.extension_pending].includes(borrow?.status) && (
            <IonCol size="3">
              <IonChip class="primary-chip" onClick={showInfo} style={chipStyle}>
                <IonLabel
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  { borrow?.days < 0 ? 'Scaduto' : `- ${borrow?.days} gg` }
                </IonLabel>
              </IonChip>
            </IonCol>
          ) }
        </IonRow>
      </IonToolbar>
    </>
  )
}

export default ChatToolbar