import {
  IonTitle,
  IonButton,
  useIonAlert,
  IonIcon,
  IonRow,
  IonCol,
  useIonViewDidEnter,
  useIonViewWillLeave,
  IonLabel,
  useIonLoading,
} from '@ionic/react'
import axios from 'axios'
import { barcodeOutline, closeOutline } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { useToast } from '../../providers/ToastProvider'
import { readIsbnBarcode, stopScan } from '../../plugins/barcode'
import { useDevice } from '../../providers/DeviceProvider'
import SecondaryLayout from '../../layout/SecondaryLayout'
import SecondaryToolbar from '../../components/SecondaryToolbar'

const SearchIsbn: React.FC = () => {
  const history = useHistory()
  const [present] = useIonAlert()
  const toast = useToast()
  const { isWeb } = useDevice()
  const [presentLoading, dismissLoading] = useIonLoading()

  const searchIsbn = async (isbn: string | undefined) => {
    presentLoading('Caricamento')
    if(!isbn || (isbn?.length !== 13 && isbn?.length !== 10)) return

    try {
      const response = await axios.get(`books/isbn/${isbn}`)

      history.push('/new_book/review', { book: response.data, type: 'isbn' })
    } catch (error) {
      dismissLoading()
      stopScan()
      console.error(error)
      toast.error(`Errore nel recupero del libro con isbn: ${isbn}`)

      setTimeout(async () => {
        const retryIsbn = await readIsbnBarcode()
        searchIsbn(retryIsbn)
      }, 1500)
      
      //  codeReader.reset()
    }

    dismissLoading()
  }

  const openManual = async () => {
    await stopScan()
    present({
      header: 'Codice ISBN',
      inputs: [
        {
          name: 'isbn',
          type: 'text',
          placeholder: 'Inserisci codice ISBN',
        },
      ],
      buttons: [
        { text: 'Indietro', role: 'cancel', handler: async () => await openScan() },
        { text: 'Cerca', role: 'confirm', handler: (d) => searchIsbn(d.isbn) },
      ],
    })
  }

  const openScan = async () => {
    const isbn = await readIsbnBarcode()

    if (isbn)
      searchIsbn(isbn)
  }

  useIonViewWillLeave(() => {
    stopScan()
    dismissLoading()
  })

  useIonViewDidEnter(async () => {
    await openScan()
  })

  const buttonStyle = {
    marginTop: 7,
    marginLeft: 50,
    marginRight: 50,
    fontSize: 14,
  }

  const backIconStyle = {
    fontSize: 20,
    padding: 10,
  }

  return (
    <SecondaryLayout
      scrollY={false}
      toolbar={
        <div style={{ backgroundColor: 'white' }} >
          <SecondaryToolbar className="secondary-toolbar">
            <IonTitle>
              Aggiungi un libro
            </IonTitle>
            <IonIcon
              icon={closeOutline}
              onClick={() => { history.replace('/myShelf') }}
              slot="end"
              style={backIconStyle}
            />
          </SecondaryToolbar>
        </div >
      }
    >
      <div className="scan-info-top">
        <IonRow class="ion-no-padding" style={{ marginBottom: 10 }}>
          <IonCol class="ion-no-padding">
            <IonLabel style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Scannerizza il Codice a Barre</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonIcon icon={barcodeOutline} style={{ fontSize: 'xx-large' }} />
          </IonCol>
        </IonRow>
      </div>
      <div className="scan-info-bottom">
        <IonRow style={{ marginBottom: 10 }} >
          <IonCol style={{ fontSize: 13 }}>
            Mettiti in un luogo ben illuminato, mantieni la camera a circa 10 cm, tieni la mano ferma per qualche secondo 😊
          </IonCol>
        </IonRow>
        <IonButton
          expand="block"
          fill="outline"
          onClick={openManual}
          shape="round"
          size="small"
          style={buttonStyle}
        >
          Inserisci manualmente
        </IonButton>
        <IonButton
          expand="block"
          fill="outline"
          onClick={() => history.push('/new_book/search_from_list')}
          shape="round"
          size="small"
          style={buttonStyle}
        >
          Non lo trovi?
        </IonButton>
      </div>

      { isWeb ?
        <div className="scan-box">
          <div className="scan-box-delimiters" >
            <em />
          </div>
          <video
            height="400"
            id="video"
            width="100%"
          />
        </div>
        :
        <div className="scan-box">
          <div className="scan-box-delimiters" >
            <em />
          </div>
        </div> }
    </SecondaryLayout >
  )
}

export default SearchIsbn
