import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { setup } from './plugins/axios'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './index.css'
import 'swiper/css'
import 'swiper/css/pagination'
import { defineCustomElements } from '@ionic/pwa-elements/loader'

// import Swiper core and required modules
import SwiperCore, {
  Pagination,
} from 'swiper'

setup()

// install Swiper modules
SwiperCore.use([Pagination])

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

defineCustomElements(window)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();