import {
  IonToolbar,
  IonTitle,
  IonIcon,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonSearchbar,
} from '@ionic/react'
import {
  funnel,
  funnelOutline,
  lockOpen,
  lockClosed,
  chevronForwardOutline,
  chevronBackOutline,
  filterOutline,
} from 'ionicons/icons'
import { ShelfToolbarProps } from '~types/components'

const ShelfToolbar: React.FC<ShelfToolbarProps> = ({ setSearch, params, setParams }: ShelfToolbarProps) => {
  const hasFilterSelected = () => {
    return params.statuses.public === 1 || params.statuses.private === 1 || params.statuses.loaned === 1 || params.statuses.borrowed === 1
  }

  const iconStyle = {
    fontSize: 22,
  }

  return (
    <>
      <IonToolbar class="light-toolbar">
        <IonTitle style={{ textTransform: 'uppercase', fontSize: 16 }}>
          La mia Libreria
        </IonTitle>
        <IonIcon
          class="ion-padding"
          color={hasFilterSelected() ? 'primary' : 'grey'}
          icon={hasFilterSelected() ? funnel : funnelOutline}
          id="trigger-filter-myShelf"
          slot="end"
          style={iconStyle}
        />
        <IonPopover class="filters-popover" trigger="trigger-filter-myShelf">
          <IonContent class="ion-text-center" scrollY={false}>
            <IonList class="ion-no-padding">
              <IonItem lines="full">
                <IonIcon color="primary" icon={lockOpen} slot="start" style={{ fontSize: 20 }} />
                <IonLabel>
                  <small>Pubblici</small>
                </IonLabel>
                <IonCheckbox
                  checked={!!params.statuses.public}
                  color="primary"
                  onIonChange={(e) => { setParams({ ...params, statuses: { ...params.statuses, public: e.detail.checked ? 1 : 0 } }) }}
                  slot="end"
                />
              </IonItem>
              <IonItem lines="full">
                <IonIcon color="secondary" icon={lockClosed} slot="start" style={{ fontSize: 20 }} />
                <IonLabel>
                  <small>Privati</small>
                </IonLabel>
                <IonCheckbox
                  checked={!!params.statuses.private}
                  color="primary"
                  onIonChange={(e) => { setParams({ ...params, statuses: { ...params.statuses, private: e.detail.checked ? 1 : 0 } }) }}
                  slot="end"
                />
              </IonItem>
              <IonItem lines="full">
                <IonIcon icon={chevronForwardOutline} slot="start" style={{ fontSize: 20 }} />
                <IonLabel>
                  <small>Dati in Prestito</small>
                </IonLabel>
                <IonCheckbox
                  checked={!!params.statuses.loaned}
                  color="primary"
                  onIonChange={(e) => { setParams({ ...params, statuses: { ...params.statuses, loaned: e.detail.checked ? 1 : 0 } }) }}
                  slot="end"
                />
              </IonItem>
              <IonItem lines="none">
                <IonIcon icon={chevronBackOutline} slot="start" style={{ fontSize: 20 }} />
                <IonLabel>
                  <small>Presi in Prestito</small>
                </IonLabel>
                <IonCheckbox
                  checked={!!params.statuses.borrowed}
                  color="primary"
                  onIonChange={(e) => { setParams({ ...params, statuses: { ...params.statuses, borrowed: e.detail.checked ? 1 : 0 } }) }}
                  slot="end"
                />
              </IonItem>
            </IonList>
          </IonContent>
        </IonPopover>
        <IonIcon
          class="ion-padding"
          color="primary"
          icon={filterOutline}
          onClick={() => { setParams({ ...params, order: params.order === 'desc' ? 'asc' : 'desc' }) }}
          slot="end"
          style={{ ...iconStyle, ...((params.order === 'asc' || params.order === undefined) && { transform: 'rotate(180deg)' }) }}
        />
      </IonToolbar>
      <IonToolbar class="light-toolbar">
        <IonSearchbar
          debounce={750}
          onIonChange={e => {
            setSearch(e.detail.value!)
          }}
          placeholder="Cerca nella tua libreria"
        />
      </IonToolbar>
    </>
  )
}

export default ShelfToolbar