import {
  IonButton,
} from '@ionic/react'
import { ChatOptionProps } from '~types/components'

const ChatOption: React.FC<ChatOptionProps> = ({ options }: ChatOptionProps) => {
  return (
    <>
      {
        options.map((option: any, index: number) => {
          return (
            <IonButton expand='block' key={index} onClick={option.onClick} shape="round" size="small">
              { option.label }
            </IonButton>
          )
        })
      }
    </>
  )
}

export default ChatOption