import {
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  RefresherEventDetail,
  useIonViewDidEnter,
  useIonAlert,
} from '@ionic/react'
import axios from 'axios'
import {
  add,
} from 'ionicons/icons'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import BookCard from '../components/BookCard'
import { useToast } from '../providers/ToastProvider'
import { useAuth } from '../providers/AuthProvider'
import DefaultLayout from '../layout/DefaultLayout'
import ShelfToolbar from '../components/ShelfToolbar'
import { ShelfFilterParams } from '~types/shelf'

const MyShelf: React.FC<RouteComponentProps> = () => {
  const defaultFilterParams: ShelfFilterParams = {
    statuses: {
      public: 0,
      private: 0,
      loaned: 0,
      borrowed: 0,
    },
    order: undefined,
  }

  const { user, b2bUser } = useAuth()
  const history: any = useHistory()
  const [shelfs, setShelfs] = useState<any>([])
  const filteredShelfs = useMemo(() => {
    return shelfs.map((s: any) => {
      s.book.status = s.status
      s.book.borrow = s.borrow
      return s
    })
  }, [shelfs])
  const [loading, setLoading] = useState(true)
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false)
  const [search, setSearch] = useState('')
  const [present] = useIonAlert()
  const [page, setPage] = useState({ page: 1 })
  const toast = useToast()
  const [params, setParams] = useState<ShelfFilterParams>(defaultFilterParams)
  const [refreshEvent, setRefreshEvent] = useState<any>()
  const [infiniteEvent, setInfiniteEvent] = useState<any>()

  const loadShelfs = async () => {
    setLoading(true)

    try {
      const { data, meta }: any = await axios.get('shelfs', {
        params: {
          search,
          page: page.page,
          ...params.statuses,
          order: params.order,
        },
      })

      setShelfs([...shelfs, ...data])
      setDisableInfiniteScroll(data.length < meta.limit || data.length === 0)

      setLoading(false)
    } catch (error: any) {
      if (!error.__CANCEL__) {
        console.error(error)

        toast.error('Errore nel caricamento dei libri')

        setLoading(false)
      }
    }

    infiniteEvent?.target.complete()
    refreshEvent?.detail.complete()
  }

  const loadMoreShelfs = (event: any) => {
    setInfiniteEvent(event)
    setPage({ page: page.page + 1 })
  }

  const refreshShelfs = async (event: CustomEvent<RefresherEventDetail> | null = null) => {
    setRefreshEvent(event)
    setShelfs([])
    setPage({ page: 1 })
  }

  const addNewBook = () => {
    if (b2bUser?.latitude === null && b2bUser?.longitude === null) {
      present({
        header: 'Aggiungi Libro',
        message: 'Per aggiungere un nuovo libro devi inserire il tuo indirizzo.<br/> Questo serve per migliorare l\'esperienza d\'uso di Book2Book.<br/> Andare alla modifica utente?',
        buttons: [
          'Annulla',
          { text: 'Ok', handler: () => history.push('/profile', { modalOpen: true, returnTo: '/myShelf' }) },
        ],
      })
    } else {
      history.push('/new_book/search_isbn')
    }
  }

  useEffect(() => {
    if (user) {
      loadShelfs()
    }
  }, [user, page])

  useEffect(() => {
    refreshShelfs()
  }, [search, params])

  useEffect(() => {
    if (history.location.state?.refresh_shelf) {
      refreshShelfs()
      delete history.location.state.refresh_shelf
    } else if (history.location.state?.refresh_user) {
      delete history.location.state.refresh_user
    }
  }, [history.location.state])

  useIonViewDidEnter(() => {
    if (localStorage.getItem('refresh_shelf')) {
      refreshShelfs()
      localStorage.removeItem('refresh_shelf')
    }
  })

  return (
    <DefaultLayout
      loading={loading}
      toolbar={<ShelfToolbar {...{ search, setSearch, params, setParams }} />}
    >
      <IonRefresher onIonRefresh={refreshShelfs} slot="fixed">
        <IonRefresherContent />
      </IonRefresher>

      { filteredShelfs.length === 0 && !loading && (
        <IonGrid>
          { /* <IonRow>
              <IonCol size="12" class="ion-text-center">
                <IonText>
                  {search !== "" ? "Nessun risultato trovato" : "Aggiungi il tuo primo libro!"}
                </IonText>
              </IonCol>
            </IonRow> */ }

          <IonRow class="ion-justify-content-center ion-margin-top">
            <IonCol size="8">
              <IonImg src="/images/empty-shelf.svg" />
            </IonCol>
          </IonRow>
        </IonGrid>
      ) }
      { filteredShelfs.length !== 0 && (
        <IonGrid>
          { filteredShelfs.map((shelf: any, x: any) =>
            (<BookCard
              book={shelf.book}
              isInLibrary
              key={x}
              onClick={() => history.push(`/myShelf/${shelf.id}`)}
            />),
          ) }
        </IonGrid>
      ) }
      <IonFab
        horizontal="end"
        slot="fixed"
        style={{ bottom: 63 }}
        vertical="bottom"
      >
        <IonFabButton color="primary" onClick={addNewBook}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>

      <IonInfiniteScroll
        disabled={disableInfiniteScroll}
        onIonInfinite={loadMoreShelfs}
        threshold="1%"
      >
        <IonInfiniteScrollContent
          loadingSpinner="dots"
          loadingText="Caricamento in corso..."
        />
      </IonInfiniteScroll>
    </DefaultLayout>
  )
}

export default MyShelf
