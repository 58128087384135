import {
  IonCard,
  IonCardSubtitle,
  IonCardContent,
  IonRow,
  IonCol,
  IonLabel,
  IonIcon,
  IonChip,
  IonText,
} from '@ionic/react'
import { chevronForwardOutline, lockClosed, lockOpen } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { shelfStatuses } from '../enums/ShelfStatuses'

import { BookCardProps } from '~types/components'

const BookCard: React.FC<BookCardProps> = ({ style, book, onClick, isInLibrary }: BookCardProps) => {
  const history = useHistory()

  const redirectToHostProfile = (e: any, host_id: string) => {
    e.stopPropagation()
    history.push(`/user/${host_id}`)
  }

  const distance = (d: number) => {
    return d < 1000 ?
      d + ' m da te'
      :
      Number((d / 1000).toFixed(1)) + 'km da te'
  }

  const cardStyle = {
    background: isInLibrary
      ? '#e9e9e9'
      : 'rgba(255, 130, 67, 0.25)',
    borderRadius: 30,
    margin: 13,
  }

  if (isInLibrary) {
    switch (book.status) {
      case shelfStatuses.loaned:
      case shelfStatuses.borrowed:
        cardStyle.background = 'rgba(255, 51, 112, 0.25)'
        break
      case shelfStatuses.public:
        cardStyle.background = 'rgba(255, 130, 67, 0.25)'
        break
      case shelfStatuses.private:
        cardStyle.background = '#e9e9e9'
        break
    }
  }

  const iconStyle = {
    fontSize: 25,
    marginTop: 35,
    float: 'right',
  }

  const distanceStyle = {
    marginTop: 35,
    float: 'right',
  }

  const chipStyle = {
    fontSize: 13,
  }

  const bookTitleStyle = {
    fontSize: '15px', 
    fontWeight: 'bold',
    display: '-webkit-box', 
    overflow: 'hidden', 
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  }
  
  const bookAuthorStyle= {
    textTransform: 'none', 
    fontWeight: 'lighter',
    display: '-webkit-box',
    overflow: 'hidden', 
    WebkitLineClamp: 1, 
    WebkitBoxOrient: 'vertical', 
  }

  return (
    <IonCard onClick={onClick} style={{ ...cardStyle, ...style }}>
      <IonCardContent style={{ padding: 14 }}>
        <IonRow class="ion-align-item-center">
          <IonCol size="3">
            <img src={book.thumbnail
              ? book.thumbnail
              : '/images/missing-book.png'} style={{ padding: 5, maxHeight: 100 }} />
          </IonCol>
          <IonCol>
            <IonLabel style={bookTitleStyle}>{ book.title }</IonLabel>
            <IonCardSubtitle style={bookAuthorStyle}>{ book.author }</IonCardSubtitle>
            { isInLibrary ?
              <>
                { [shelfStatuses.loaned, shelfStatuses.borrowed].includes(book.status) && book.borrow && book.borrow?.synced_at ? 
                  <IonChip class="primary-chip" style={chipStyle}>
                    <IonLabel>{ book.borrow?.days < 0
                      ? 'Scaduto'
                      : `- ${book.borrow?.days} gg` }</IonLabel>
                  </IonChip>
                  : null }
                { book.status === shelfStatuses.borrowed && book.borrow
                  ? <IonChip class="light-chip"  style={chipStyle}>
                    <IonLabel color="dark" style={{ textTransform: 'uppercase' }}>In Prestito</IonLabel>
                  </IonChip>
                  : null }
                
                { book.status === shelfStatuses.loaned && book.borrow
                  ? <IonChip class="light-chip" onClick={(e) => redirectToHostProfile(e, book.borrow?.user?.id)} style={chipStyle}> 
                    <IonLabel color="dark">{ book.borrow?.user?.alias }</IonLabel> 
                    <IonIcon icon={chevronForwardOutline} />
                  </IonChip>
                  : null }
              </>
              :
              book.status === shelfStatuses.loaned && (
                <IonRow>
                  <IonCol class="ion-no-padding">
                    <IonChip color="secondary" >
                      <IonLabel color="dark">In Prestito</IonLabel>
                    </IonChip>
                  </IonCol>
                </IonRow>
              ) }
            { !isInLibrary && (
              <IonRow>
                <IonCol class="ion-no-padding">
                  { book.host?.alias }
                </IonCol>
              </IonRow>
            ) }
            { isInLibrary && book.status !== shelfStatuses.loaned ? 
              <IonIcon
                color={book.status === shelfStatuses.private
                  ? 'secondary'
                  : 'primary'}
                icon={book.status === shelfStatuses.private
                  ? lockClosed
                  : lockOpen}
                style={iconStyle}
              />
              : null }
            { book.distance ? 
              <IonText color="secondary" style={distanceStyle}>
                { distance(book.distance) }
              </IonText>
              : null }
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  )
}

export default BookCard