import { IonTitle, IonToolbar } from '@ionic/react'
import FormProfile from '../components/FormProfile'
import SecondaryLayout from '../layout/SecondaryLayout'
import { useAuth } from '../providers/AuthProvider'
import { useLocation } from 'react-router'

const FirstLogin: React.FC = () => {
  const { b2bUser } = useAuth()

  const displayName = new URLSearchParams(useLocation().search).get('displayName')

  return (
    <SecondaryLayout
      toolbar={
        <IonToolbar>
          <IonTitle>
            Compila tutti i campi
          </IonTitle>
        </IonToolbar>
      }
    >
      <FormProfile user={{ ...b2bUser, displayName }} />
    </SecondaryLayout>
  )
}

export default FirstLogin