import {
  IonCard,
  IonCardContent,
  IonLabel,
  IonItem,
  IonAvatar,
  IonImg,
  IonIcon,
} from '@ionic/react'
import { useMemo } from 'react'
import { useHistory } from 'react-router'
import { UserCardProps } from '~types/components'

const UserCard: React.FC<UserCardProps> = ({ style, user, onClick }: UserCardProps) => {
  const history: any = useHistory()

  const redirectToHostDetails = () => {
    if (user.bookpoint) {
      history.push(`/bookPoints/${user?.id}`)
    } else {
      history.push(`/user/${user?.id}`)
    }
  }

  const onClickInternal = (e: any, fn: any) => {
    e.stopPropagation()
    fn()
  }

  const avatar = useMemo(() => {
    if(!user?.avatar || user?.avatar === '') return '/images/no-avatar.png'
    return user?.avatar
  }, [user])

  const label = useMemo(() => {
    if(user?.bookpoint) return user?.full_name
    return user?.alias
  }, [user]) 

  const cardStyle = {
    background: 'rgba(255, 130, 67, 0.25)',
    borderRadius: 30,
    margin: 13,
  }

  const bookPointAvatarStyle = { 
    background: user.type !== 'other' ? 'linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 100%)' : null,
    display: user.type === 'other' ? 'flex' : null,
    alignItems: user.type === 'other' ? 'center' : null,
    justifyContent: user.type === 'center' ? 1 : null,
    backdropFilter: user.type === 'other' ? 'brightness(80%)' : null,
    width: 30,
    height: 30,
  }

  const bookPointIconStyle = {
    color: user.color, 
    fontSize: 23,
    textAlign: 'center',
    flexGrow: 1,
  }

  return (
    <IonCard 
      onClick={(e) => onClickInternal(e, onClick ?? redirectToHostDetails)} 
      style={{ ...cardStyle, ...style }}
    >
      <IonCardContent style={{ padding: 0 }}>
        <IonItem button color="rgba(255, 130, 67, 0.25)" lines="none">
          { user.bookpoint ? 
            <IonAvatar slot="start" style={bookPointAvatarStyle}>
              { user.type === 'other' ? 
                <IonIcon icon={user.icon} style={bookPointIconStyle}/>
                :
                <IonImg src="/assets/icon/favicon.png" style={{ width: 30, height: 30 }} /> }
            </IonAvatar>
            :
            <IonAvatar slot="start" style={{ width: 30, height: 30 }}>
              <img src={avatar} />
            </IonAvatar> }
          <IonLabel>{ label }</IonLabel>
        </IonItem>
      </IonCardContent>
    </IonCard>
  )
}

export default UserCard